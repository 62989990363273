import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import { Link, NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../store/auth-context";
import { getTokenDetails } from "../utils/auth";


const Sidebar = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [businessName, setBusinessName] = useState(null);
  useEffect(() => {
    let { role , business_name } = getTokenDetails();
    setRole(role);
    setBusinessName(business_name)
  }, []);

  function handleSignOut() {
    localStorage.removeItem("token");
    navigate("/admin");
  }
  return (
    <div className="sm:w-[15%] min-h-[80vh] bg-[#fff] sticky top-[6%] h-fit">
      <div className="w-[80%] mx-auto">
        <button
          
          className="flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline sm:text-sm 2xl:text-xl [&amp;[data-state=open]>svg]:rotate-180 text-[#5578eb] w-full text-xl  "
        >
          Welcome {businessName}
        
        </button>
      </div>
      <div className="w-[80%] mx-auto">
        <Accordion type="single" collapsible>
          <AccordionItem value="item-1">
            <AccordionTrigger className="text-[#5578eb] w-full text-xl">
              <i className="fa fa-file"> </i>Certificates
            </AccordionTrigger>
            <NavLink
              to={"/new"}
              className={({ isActive, isPending }) =>
                isActive ? "text-[var(--primary)]" : "text-[#73718b]"
              }
            >
              <AccordionContent>• Issue Certificate</AccordionContent>
            </NavLink>

            <NavLink
              to={"/view"}
              className={({ isActive, isPending }) =>
                isActive ? "text-[var(--primary)]" : "text-[#73718b]"
              }
            >
              <AccordionContent>• View Certificates</AccordionContent>
            </NavLink>

            <NavLink
              to={"/upload"}
              className={({ isActive, isPending }) =>
                isActive ? "text-[var(--primary)]" : "text-[#73718b]"
              }
            >
              <AccordionContent>• Bulk Upload</AccordionContent>
            </NavLink>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="w-[80%] mx-auto">
        <Accordion type="single" collapsible>
          <AccordionItem value="item-1">
            <AccordionTrigger className="text-[#5578eb] w-full text-xl">
              <i className="fa fa-home"> </i>Your Account
            </AccordionTrigger>

            {role === "DEALER" && (
              <NavLink
                to={"/"}
                className={({ isActive, isPending }) =>
                  isActive ? "text-[var(--primary)]" : "text-[#73718b]"
                }
              >
                <AccordionContent>• Account Overview</AccordionContent>
              </NavLink>
            )}

            <NavLink
              to={"/payment/outstanding"}
              className={({ isActive, isPending }) =>
                isActive ? "text-[var(--primary)]" : "text-[#73718b]"
              }
            >
              <AccordionContent>• Outstanding Payments Payout</AccordionContent>
            </NavLink>

            <NavLink
              to={"/profile"}
              className={({ isActive, isPending }) =>
                isActive ? "text-[var(--primary)]" : "text-[#73718b]"
              }
            >
              <AccordionContent>• Profile</AccordionContent>
            </NavLink>
            <NavLink
              to={"/contactUs"}
              className={({ isActive, isPending }) =>
                isActive ? "text-[var(--primary)]" : "text-[#73718b]"
              }
            >
              <AccordionContent>• Contact Us</AccordionContent>
            </NavLink>

            {/* <NavLink to={'/terms-conditions'} className={({ isActive, isPending }) =>
                          isActive ? "text-[var(--primary)]" : "text-[#73718b]"}>
                          <AccordionContent>
                            Terms

                          </AccordionContent>
                      </NavLink> */}

            {role === "ADMIN" && (
              <>
                <NavLink
                  to={"/payment/outstanding-admin"}
                  className={({ isActive, isPending }) =>
                    isActive ? "text-[var(--primary)]" : "text-[#73718b]"
                  }
                >
                  <AccordionContent>
                    • Outstanding Payments Payout Admin
                  </AccordionContent>
                </NavLink>

                <NavLink
                  to={"/newDealer"}
                  className={({ isActive, isPending }) =>
                    isActive ? "text-[var(--primary)]" : "text-[#73718b]"
                  }
                >
                  <AccordionContent>• New Dealer</AccordionContent>
                </NavLink>

                <NavLink
                  to={"/getDealers"}
                  className={({ isActive, isPending }) =>
                    isActive ? "text-[var(--primary)]" : "text-[#73718b]"
                  }
                >
                  <AccordionContent>• View Dealers</AccordionContent>
                </NavLink>

                <NavLink
                  to={"/dealerOutstanding"}
                  className={({ isActive, isPending }) =>
                    isActive ? "text-[var(--primary)]" : "text-[#73718b]"
                  }
                >
                  <AccordionContent>• Dealer Outstanding</AccordionContent>
                </NavLink>

                <NavLink
                  to={"/create-plan"}
                  className={({ isActive, isPending }) =>
                    isActive ? "text-[var(--primary)]" : "text-[#73718b]"
                  }
                >
                  <AccordionContent>• Create plan</AccordionContent>
                </NavLink>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </div>

      <div className="w-[80%] mx-auto">
        <button
          onClick={handleSignOut}
          className="flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline sm:text-sm 2xl:text-xl [&amp;[data-state=open]>svg]:rotate-180 text-[#5578eb] w-full text-xl  "
        >
          <i className="fa fa-sign-out" aria-hidden="true"></i>Logout
          <i></i>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
