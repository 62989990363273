import { getTokenDetails } from '../../utils/auth';
import AuthContext from '../../store/auth-context';
import React , {useState , useEffect , useContext} from 'react'



const Step2 = ({data,setData}) => {
    const context = useContext(AuthContext);

    const [planList, setplanList] = useState([]);
    const [minDate, setMinDate] = useState('');

    useEffect(() => {
        getPlans();
        dateHandler('current');
        
    }, []);

    const handleChange = (e) => {
       
        let value = e.target.value
        let ele = document.getElementsByName(e.target.name)[0].parentNode;
        let errorText = ele.querySelector('.error-text')
        if (value !== '' && errorText) {
            errorText && ele.removeChild(errorText)
        }
        
     

        if (e.target.name === 'plan') {
        
            let planValue = e.target[e.target.selectedIndex].getAttribute('plan')
            // console.log(planValue)
            setData({
                ...data,
                [e.target.name]: value,
                'plan_amount': planValue,
                'plan_id': e.target[e.target.selectedIndex].getAttribute('id'),
                'gst_amount':String(((Number(planValue)*18)/100)),
                'total_amount':String(Math.round((Number(planValue) + ((Number(planValue) * 18) / 100))))
            });
            
            

        } else if(e.target.name === 'start_date'){
            dateHandler(value);

            } else{
            // console.log(e.target.name, value)
            setData({
                ...data,
                [e.target.name]: value,

            });

        }

        // console.log(data)

        
       
    }




    async function getPlans(vehicle) {
        let {token} = getTokenDetails();
       
        const getPlans = 'https://spa-insurance.forklyft.in/v1/dealer/get-vehicle-plans'

        try {
            fetch(getPlans, {
                headers: {

                    'Authorization': 'Bearer ' + token
                }
            })
                .then(async (response) => {
                    let resp = await response.json()


                    // console.log(resp.message, "#Plans");
                    setplanList(resp.message)
                   

                });



        } catch (error) {
            alert("Something went wrong.")
            return

        }

    }

    function dateHandler(period) {
       

       if (period==='current'){
           const today = new Date();
           const year = today.getFullYear();
           const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
           const day = String(today.getDate()).padStart(2, '0');
            setMinDate(`${year}-${month}-${day}`)
           setData({
               ...data,
               'start_date': `${year}-${month}-${day}`,
               'end_date': `${year + 1}-${month}-${day}`,
              

           });
            return
       } 
           const start = new Date(period);
           const syear = start.getFullYear();
           const smonth = String(start.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
           const sday = String(start.getDate()).padStart(2, '0');
       

           setData({
               ...data,
               'start_date': period,
               'end_date': `${syear+1}-${smonth}-${sday}`,

           });

       
    }



  return (
      <div className='w-[60%] '>
          <h2 className='text-[#000] font-semibold text-2xl text-left'>Plan Details</h2>
          <div className=' w-full flex mt-[5%] justify-between flex-wrap gap-y-[1.5rem]'>


              <div className="form-group w-[45%] text-left">
                  <label>Select Plan</label>
                  <div className="dropdown bootstrap-select form-control kt_">
                     
                      <select className="" name="plan" value={data.plan} onChange={handleChange} >
                          <option value='' hidden>Select Plan</option>
                          {planList.map((plan , key) => (
                            <option plan={plan.price} id={plan.id} value={plan.name} key={plan.id}>{plan.name}</option>
                          ))}
                      </select>
                  </div>
              </div>

              <div className="form-group w-[45%] text-left">
                  <label>Plan Amount</label>
                  <div className="custom-input">
                      <span>₹</span>
                     <input type="text" name='plan_amount' value={data.plan_amount}  disabled/>
                  </div>
              </div>

              <div className="form-group w-[45%] text-left">
                  <label>GST Amount</label>
                  <div className="custom-input">
                      <span>₹</span>
                      <input type="text" name='gst_amount' value={data.gst_amount}  disabled/>
                  </div>
              </div>

              <div className="form-group w-[45%] text-left">
                  <label>Total Amount</label>
                  <div className="custom-input">
                      <span>₹</span>
                      <input type="text" name='total_amount' value={data.total_amount}   disabled/>
                  </div>
              </div>

              <div className="form-group w-[45%] text-left">
                  <label>Start Date</label>
                  <div className="custom-input">
                      <span><i className="fa">&#xf073;</i></span>
                      <input min={minDate} name='start_date' type="date" onChange={handleChange} value={data.start_date}  />
                  </div>
              </div>

              <div className="form-group w-[45%] text-left">
                  <label>End Date</label>
                  <div className="custom-input">
                      <span><i  className="fa">&#xf073;</i></span>
                      <input type="date" name='end_date' value={data.end_date} disabled/>
                  </div>
              </div>

             


           
          </div>



          <div>

          </div>
      </div>
  )
}

export default Step2