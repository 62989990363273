import React , {useContext} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthContext from '../../store/auth-context';
import { getTokenDetails } from '../../utils/auth';

const Paynow = () => {
    const context = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state
     const { total_amount, instrumentResponse } = data.list;
     let calculatedAmount = Number(total_amount / 100);
    console.log(data, "POPO")
    let dealerDetails = 'ss';



    return (
        <div className='w-[80%]'>
            <h1 className='text-left text-2xl font-semibold mb-4'>Overview</h1>


            {dealerDetails === '' ? (

                <svg className="animate-spin -ml-1 mr-3 h-[4%] my-[14vw]  w-full text-[var(--primary)] text-xl" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            ) :
                (

                    <div className='flex bg-[transparent] w-full justify-between flex-col gap-4'>
                        <div className='bg-[#fff] flex flex-col items-start justify-between w-full pb-8 '>
                            <h2 className='text-left pl-8 py-6 mb-8 text-xl font-[400] w-full border-b-2 p'>Payment Summary</h2>


                            <div className="flex w-full justify-between px-8">
                                <div className='flex justify-between w-[45%]'>
                                    <div className='text-left text-[#646c9a] font-bold w-full'>
                                        <div className='flex w-full justify-between'>
                                            <p className='mb-1'>Selected Certificates</p>
                                            <p>{data.list.length}</p>

                                        </div>
                                        <div className='flex w-full justify-between'>
                                            <p className='mb-1'>Certificate Premium Payable</p>
                                            <p>₹{calculatedAmount}</p>

                                        </div>


                                        <span className='text-sm font-[400] text-[#646c9a]  w-full '>(including GST)</span>

                                        <div className='flex w-full justify-between border-t-2 pt-4 mt-4'>
                                            <p className='mb-1'>Net Payment Due</p>
                                            <p>₹{calculatedAmount}</p>

                                        </div>

                                        <p className='mb-1 text-sm font-[400] text-[#646c9a]'>Qualified Certificate Commission Payout</p>
                                    </div>


                                </div>


                                <div className='w-[40%] flex flex-col justify-start align-center'>
                                    <h3 className='text-2xl text-left px-4 py-3 bg-[#F9F9FC] text-[#646c9a] w-[70%]'>Select Options to pay ₹{calculatedAmount}</h3>

                                    <div className='flex border-2 p-4 my-4 w-[70%]'>
                                    <i className='w-[30%] fa fa-credit-card text-3xl'></i>
                                    <div className='w-[70%] text-left'>
                                            <p className=''> You may now pay via Credit/Debit Card, Net Banking, UPI, and Wallets</p>
                                            <a href={instrumentResponse.redirectInfo.url}>

                                            <button  className='my-6 bg-[#1dc9b7]  text-[#fff] rounded-xl py-2 px-3 text-md'>PAY NOW ₹{calculatedAmount}</button>
                                            </a>
                                    </div>

                                    </div>
                                </div>

                            </div>

                          
                        </div>


                    </div>

                )}


            <div className='text-[#646c9a] py-8 text-left'>
                2024 © Powered by Across Assist
            </div>
        </div>
    )
}

export default Paynow