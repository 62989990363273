import React , {useState , useContext} from 'react'
import {
    DeleteObjectCommand,
    PutObjectCommand,
    S3Client,
    s3
} from '@aws-sdk/client-s3';
import { EXCEL_FILE_BASE64 } from '../constants';

import { v4 as uuidv4 } from "uuid";
import AuthContext from '../store/auth-context';
import FileSaver from 'file-saver';
import { getTokenDetails } from '../utils/auth';


const Upload = () => {
    const [excelfile, setExcelfile] = useState('');
    const context = useContext(AuthContext);




    const handleDownload =() => {
        let sliceSize = 1024;
        let byteCharacters = atob(EXCEL_FILE_BASE64);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);
        for(let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * sliceSize;
    let end = Math.min(begin + sliceSize, bytesLength);
    let bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
}
FileSaver.saveAs(
    new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
    "Bharti Assist RSA Certificate Issue Template.xlsx"
);
  };


    const bulkUpload =async  (file) => {
        let tokenDetails = getTokenDetails()

        try {

            const response = await fetch("https://spa-insurance.forklyft.in/v1/dealer/bulk-upload", {

                // Adding method type
                method: "POST",

                // Adding body or contents to send
                body: JSON.stringify({
                    file: file
                }),

                // Adding headers to the request
                headers: {
                    "Content-type": "application/json",
                      'Authorization': 'Bearer ' + tokenDetails.token,
                }
            }

            )

            const resp = await response.json();
            alert(resp.message)
            setExcelfile('')
            // if (resp.status === 200) {
            //     // console.log(resp)




            // } 
            
        } catch (error) {
            console.log(error)
            
        }

      


    }


    const handleUpload = async () => {
        // console.log(process.env.REACT_APP_AWS_ACCESSKEY)
        console.log(excelfile)
        const client = new S3Client({
            region: "ap-south-1",
            credentials: {
                accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY,
                secretAccessKey: process.env.REACT_APP_AWS_SECRETKEY,
            },
        });

        const params = {
            Bucket: "spa-insurance",
            Key:  uuidv4() + '.xlsx',
            Body: excelfile /** object body */,
        };

        const command = new PutObjectCommand(params);
        const data = await client.send(command);
        console.log('https://d1sr2o9gkjg95z.cloudfront.net/' + params.Key);
        bulkUpload('https://d1sr2o9gkjg95z.cloudfront.net/' + params.Key)
        
    };



  return (
      <div className=' w-[80%]'>
          <h1 className='text-left text-2xl font-semibold mb-4'>Certificate Details</h1>

          <div className='flex bg-[transparent]  w-full justify-between'>

              <div className='bg-[#fff] flex flex-col items-start justify-between  w-[59%] pb-8'>
                  <h2 className=' text-left pl-8 py-6 mb-8 text-xl font-[400] w-full border-b-2'>Certificate Bulk Upload</h2>

                  <div className='flex flex-col items-center w-full mx-auto relative pb-14 border-b-2'>
                    
                    <input accept='.xls,.xlsx' className='opacity-0 z-[2] relative w-[90%] ' type="file" onChange={(e)=> { setExcelfile(e.target.files[0])}} />
                      <label className="border-2 w-[90%]  text-left absolute top-0 custom-file-label py-2 px-2 text-[#646c9a]" for="customFile">{excelfile.name ? excelfile.name : 'Choose file'}</label>
                  </div>


                  <button className='bg-[var(--primary)] text-[#fff] p-3 rounded-xl self-end mr-10 mt-4' onClick={handleUpload}>Upload</button>
             
              </div>

              <div className='bg-[#fff] flex flex-col items-start  w-[40%]'>
                  <h2 className=' text-left pl-8 py-6 mb-8 text-xl font-[400] w-full border-b-2'> Instructions for Bulk Upload</h2>

                  <div className='mx-10 pb-8'>
                      <ol className='flex flex-col items-start list-decimal'>
                          <li className='text-left text-[#646c9a] leading-7 list-decimal items-start flex flex-col'>Download the template .xls file  
                          <button onClick={handleDownload} className='hover:bg-[var(--primary)] hover:text-[#fff] rounded-xl pl-4 font-bold border-2 py-2 px-4 text]'><i className='fa fa-download mr-4'></i>DOWNLOAD TEMPLATE</button></li>
                          <li className='text-left text-[#646c9a] leading-7 list-decimal'>Populate the .xls file using the reference ids for the vehicle make, model, and plan id provided in the template file.</li>
                          <li className='text-left text-[#646c9a] leading-7 list-decimal'>Choose & upload the populated file</li>
                          <li className='text-left text-[#646c9a] leading-7 list-decimal'>Valildate the rows to be imported and submit</li>
                    </ol>
                  </div>
              
              </div>


          </div>



          <div className='text-[#646c9a] py-8 text-left'>
           
              2024 ©
              Powered by Across Assist
          </div>





      </div>
  )
}

export default Upload