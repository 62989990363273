import AuthContext from "../../../store/auth-context";
import Step1 from "../../../components/steps/step1";
import Step2 from "../../../components/steps/step2";
import Step3 from "../../../components/steps/step3";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getTokenDetails } from "../../../utils/auth";

const IssueCertificate = () => {
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const [data, setData] = useState({
    vehicle_type: "",
    vehicle_status: "",
    make: "",
    model: "",
    variant: "",
    color: "",
    engine_number: "",
    chasis_number: "",
    registration_number: "",
    plan: "",
    plan_amount: "",
    plan_id: "",
    gst_amount: "",
    total_amount: "",
    start_date: "",
    end_date: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    pincode: "",
    state: "",
    country: "",
    nominee_name: "",
    nominee_relation: "",
    nominee_dob: "",
    nominee_gender: "",
  });

  const [step, setStep] = useState(1);

  const createCertificate = async () => {
    let tokenDetails = getTokenDetails();

    const response = await fetch(
      "https://spa-insurance.forklyft.in/v1/dealer/create-certificate",
      {
        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: JSON.stringify(data),

        // Adding headers to the request
        headers: {
          Authorization: "Bearer " + tokenDetails.token,
          "Content-Type": "application/json",
        },
      }
    );
    const resp = await response.json();
    // console.log(resp)

    alert(resp.message);
    navigate("/");
  };

  function validateForm(split) {
    let fields = Object.keys(data);
    let flag = true;
    for (const field of fields) {
      if (split && field === split) {
        break;
      }
      if (data[field] === "") {
        let ele = document.getElementsByName(field)[0].parentNode;
        if (!ele.querySelector(".error-text")) {
          let label = ele.parentNode.firstChild.innerHTML;
          const node = document.createElement("p");
          node.innerHTML = label + " is Required";
          node.classList.add("text-[red]", "error-text");
          ele.appendChild(node);
        }
        flag = false;
      }
    }
    return flag;
  }

  const progressNextHandler = () => {
    if (step === 1) {
      let flag = validateForm("plan");
      flag && setStep(2);
    } else if (step === 2) {
      let flag = validateForm("first_name");
      flag && setStep(3);
    } else {
      let flag = validateForm();
      flag && createCertificate();
    }
  };

  const progressPrevHandler = () => {
    step === 2 && setStep(1);
    step === 3 && setStep(2);
  };

  return (
    <div className=" w-[80%]">
      <h1 className="text-left text-2xl font-semibold poppins">
        Drashti Assist
      </h1>

      <div className="bg-[#fff] flex-wrap flex mt-[3%] pt-[5%] justify-between px-8 ">
        <div className="progress-steps w-[35%] gap-7 flex flex-col">
          <div className="flex justify-start gap-[6%] items-center w-[60%] mx-auto">
            <span
              className={`${
                step === 1
                  ? "bg-[var(--primary)]"
                  : step > 1
                  ? "bg-[#1EC9B7]"
                  : "bg-[var(--secondary)]"
              }  flex justify-center items-center text-[2rem] rounded-[50%] w-[4vw] h-[4vw] text-white`}
            >
              {step > 1 ? "✓" : "1"}
            </span>
            <h3
              className={`${
                step === 1
                  ? "text-[var(--primary)]"
                  : step > 1
                  ? "text-[#1EC9B7]"
                  : "text-[var(--secondary)]"
              } text-[1rem] 2xl:text-xl font-[600]`}
            >
              Vehicle Details
            </h3>
          </div>

          <div className="flex justify-start gap-[6%] items-center w-[60%] mx-auto">
            <span
              className={`${
                step === 2
                  ? "bg-[var(--primary)]"
                  : step > 2
                  ? "bg-[#1EC9B7]"
                  : "bg-[var(--secondary)]"
              }  flex justify-center items-center text-[2rem] rounded-[50%] w-[4vw] h-[4vw] text-white`}
            >
              {step > 2 ? "✓" : "2"}
            </span>
            <h3
              className={`${
                step === 2
                  ? "text-[var(--primary)]"
                  : step > 1
                  ? "text-[#1EC9B7]"
                  : "text-[var(--secondary)]"
              } text-[1rem] 2xl:text-xl font-[600]`}
            >
              Plan Details
            </h3>
          </div>

          <div className="flex justify-start gap-[6%] items-center w-[60%] mx-auto">
            <span
              className={`${
                step === 3 ? "bg-[var(--primary)]" : "bg-[var(--secondary)]"
              } flex justify-center items-center text-[2rem] rounded-[50%] w-[4vw] h-[4vw] text-white`}
            >
              3
            </span>
            <h3
              className={`${
                step === 3 ? "text-[var(--primary)]" : "text-[var(--secondary)]"
              } text-[1rem] 2xl:text-xl font-[600]`}
            >
              Customer Details
            </h3>
          </div>
        </div>

        {/* ================================================================================================================================================================================================== */}

        {step === 1 && <Step1 data={data} setData={setData} />}
        {step === 2 && <Step2 data={data} setData={setData} />}
        {step === 3 && <Step3 data={data} setData={setData} />}

        {/* ================================================================================================================================================================================================== */}
        <div className="w-full flex justify-end">
          <div className="flex justify-between w-[60%]  pb-14">
            <button
              onClick={progressPrevHandler}
              className={`${
                step === 1 && "z-[-1]"
              } px-8 py-3 mt-8 rounded-[5px] text-white bg-[var(--primary)] text-xl`}
            >
              Previous
            </button>

            <button
              onClick={progressNextHandler}
              className="px-8 py-3 mt-8 rounded-[5px] text-white bg-[var(--primary)] text-xl"
            >
              Next Step
            </button>
          </div>
        </div>
      </div>

      <div className="text-[#646c9a] py-8 text-left">
        2024 © Powered by Across Assist
      </div>
    </div>
  );
};

export default IssueCertificate;
