import React , {useState,useEffect} from 'react'
import AuthContext from '../store/auth-context';
import { redirect, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
const Admin = () => {
   

    const navigate = useNavigate()

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    useEffect(() => {
        if(localStorage.getItem('token')){
           
            navigate('/')
        }
        
    }, []);


   


    const submitHandler =async  ()=> {
        if(userName === '' || userName === false){
            setUserName(false)
            return
            
        }else if(password === '' || password === false){
            setPassword(false)
            return
        }

        const response = await fetch("https://spa-insurance.forklyft.in/v1/auth/login", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({
                email: userName,
                 password: password
            }),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json"
            }
        }
        
        )

        const resp = await response.json();
        // console.log(resp)
        if(resp.status === 200){
            localStorage.setItem('token',resp.message.token)
            navigate('/')
          
           

        }else{
            setError(resp.message)
        }
        




    }

    





    return (
        <div className='flex justify-center items-center w-full  h-[100vh] bg-[#EEEEEE] flex-col'>
            <div className='bg-[#fff] p-8 w-[90%] sm:w-[40%] flex flex-col justify-center items-center gap-4 shadow-[0_2px_10px_rgba(0,0,0,.15)]'>
                <div className=" w-[80%] text-left">
                    <label className="control-label font-[600] " htmlFor="Email">Username</label>

                    <div className="">
                        <input className="border-2 w-full sm:text-md mt-2 px-4 py-2" data-val="true" data-val-required="The UserName field is required." id="UserName" name="UserName" type="text" onChange={(e) => {setUserName(e.target.value)}} />
                        {userName === false && <span className="text-[red]" data-valmsg-for="UserName" data-valmsg-replace="true">Username field is required</span>}
                    </div>
                </div>

                <div className=" w-[80%] text-left">
                    <label className="control-label font-[600] " for="Password">Password</label>
                    <div className="">
                        <input className="border-2 w-full sm:text-md mt-2 px-4 py-2" data-val="true" data-val-required="The Password field is required." id="Password" name="Password" type="password" onChange={(e) => {setPassword(e.target.value)}} />
                        {password === false && <span className="text-[red]" data-valmsg-for="UserName" data-valmsg-replace="true">Password field is required</span>}
                        {error && <span className="text-[red]" data-valmsg-for="UserName" data-valmsg-replace="true">{error}</span>}
                    </div>
                </div>

                <div className="remeber-me-login-area flex justify-end w-[95%]">
                    {/* <div className="">

                        <a className="forgot-password" href="/account/ForgotPassword/">Forgot Password ?</a>
                    </div> */}
                    <div className="">
                        <button onClick={submitHandler} className='bg-[#0096D7] text-white text-xl px-8 py-3 rounded-full'>Log in</button>
                        
                    </div>
                </div>

            </div>
            <div className='flex justify-center items-center flex-col mt-4 '>
            <p className='text-sm'>By using this portal,you agree to our <Link className='text-[var(--primary)]' to='/terms-conditions'>Terms</Link> and <Link to='/privacy-policy' className='text-[var(--primary)] ' >Privacy Policy</Link> </p>
            <p className='text-[#999] text-sm'>2023-Drashti Assist. All rights reserved.</p>

            </div>

        </div>
    )
}

export default Admin