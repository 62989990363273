import React, { useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../components/ui/dialog";
import { queryClient } from "../App";
import { getTokenDetails } from "../utils/auth";
import { getDealers, editDealerOverview , toggleDealerStatus } from "../utils/auth";

const GetDealers = () => {
  const { data } = useQuery({
    queryKey: ["dealerOverviews"],
    queryFn: getDealers,
  });
  const mutation = useMutation({
    mutationFn: editDealerOverview,
    onSuccess: () => {
      // Invalidate and refetch
      setLimit("");
      setDisableLimit(false);
      queryClient.invalidateQueries({ queryKey: ["dealerOverviews"] });
    },
  });

  const toggleMutation = useMutation({
    mutationFn: toggleDealerStatus,
    onSuccess: () => {
      // Invalidate and refetch
     
      queryClient.invalidateQueries({ queryKey: ["dealerOverviews"] });
    },
  });

  const [limit, setLimit] = useState("");
  const [disableLimit, setDisableLimit] = useState(false);

  

  return (
    <div className=" w-[80%]">
      <h1 className="text-left text-2xl font-semibold ">Dealers</h1>

      <div className="w-full bg-[#fff] flex-wrap flex mt-[2%] pt-[5%]">
        <div className="w-full mx-4">
          <ul className="certificate_table dealer_table flex w-full justify-start gap-[2%]  items-start mb-4 text-[#646c9a] font-medium text-md">
            {/* <li>Dealer Id</li> */}
            <li>Business Name</li>
            <li>Mobile Number</li>
            <li>Email</li>
            <li>Pan</li>
            <li>Total credit limit</li>
            <li>Available credit limit</li>

            <li>Manage Credit Limit</li>
            <li>Dealer Status</li>
          </ul>

          {data &&
            data.map((value, key) => (
              <div key={key} className="flex items-center">
                <div className="w-full" to={"/view/" + value.id}>
                  <ul className="certificate_table dealer_table flex w-full justify-start gap-[2%]  items-center py-4 border-b-2 border-[#f0f3ff]">
                    {/* <li >{data.id}</li> */}
                    <li className="font-semibold">{value.business_name}</li>
                    <li>{value.mobile_number}</li>
                    <li>{value.email}</li>

                    <li>{value.pan}</li>
                    <li>{value.disable_credit  ? 'disabled' : value.total_credit_limit}</li>
                    <li>{value.available_credit_limit}</li>
                    <li>
                      <Dialog>
                        <DialogTrigger className="bg-[var(--primary)] text-[#fff] rounded-xl py-2 px-6 text-[1vw] ">
                          Edit
                        </DialogTrigger>
                        <DialogContent>
                          <DialogHeader>
                            <DialogTitle>Edit Credit Limit</DialogTitle>
                            <DialogDescription>
                              <form
                                onSubmit={(event) => {
                                  event.preventDefault();
                                  let { id } = value;
                                  mutation.mutate({ id, disableLimit, limit });
                                }}
                                className="w-full flex flex-col"
                              >
                                <div className="form-group min-w-full px-4 py-8  text-left">
                                  <label>Enter Credit Limit</label>
                                  <div className="mt-4">
                                    <input
                                      value={limit}
                                      onChange={(e) => {
                                        setLimit(e.target.value);
                                      }}
                                      placeholder="Enter Credit Limit"
                                      name="color"
                                      type="text"
                                      required={!disableLimit && true}
                                    />
                                  </div>

                                  <div className="mt-4 max-w-[70%] flex justify-start items-start ">
                                    <input
                                      onChange={(e) => {
                                        setDisableLimit(e.target.checked);
                                      }}
                                      className="max-w-[10%]"
                                      placeholder=""
                                      name="color"
                                      type="checkbox"
                                    />
                                    <label className="max-w-[80%]">
                                      Disable Credit Limit
                                    </label>
                                  </div>
                                </div>
                                {limit !== "" || disableLimit ? (
                                  <DialogClose asChild>
                                    <button
                                      type="submit"
                                      className="bg-[var(--primary)] text-[#fff] rounded-xl py-2 px-3 text-[0.8vw] text-center w-[60%] mx-auto"
                                    >
                                      Submit
                                    </button>
                                  </DialogClose>
                                ) : (
                                  <button
                                    type="submit"
                                    className="bg-[var(--primary)] text-[#fff] rounded-xl py-2 px-3 text-[0.8vw] text-center w-[60%] mx-auto"
                                  >
                                    Submit
                                  </button>
                                )}
                              </form>
                            </DialogDescription>
                          </DialogHeader>
                        </DialogContent>
                      </Dialog>
                    </li>
                    <li>
                      {" "}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          let { id, active } = value;
                          toggleMutation.mutate({ id, active });
                        }}
                        className="bg-[var(--primary)] text-[#fff] rounded-xl py-2 px-6 text-[1vw]"
                      >
                        {" "}
                        {value.active ? "Deactivate" : "Activate"}
                      </button>
                    </li>
                  </ul>
                </div>
                <div></div>
              </div>
            ))}
        </div>
      </div>

      {/* <PDFViewer className="w-full h-[100vh]">
        <Pdf certId={"f56a72c5-e085-48e6-a278-ca4033c0ffe3"} />
      </PDFViewer> */}

      <div className="text-[#646c9a] py-8 text-left">
        2024 © Powered by Across Assist
      </div>
    </div>
  );
};

export default GetDealers;
