import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import React, { useEffect, useState, useContext } from "react";
import { getTokenDetails } from "../../utils/auth";

const OutstandingPaymentAdmin = () => {
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [role, setRole] = useState("ADMIN");
  const [payAll, setPayAll] = useState(false);

  const [totalPages, setTotalPages] = useState([]);
  const [certificateData, setCertificateData] = useState(null);

  useEffect(() => {
    getCertificates();
  }, [page]);

  const getCertificates = async () => {
    let tokenDetails = getTokenDetails();
    setRole(tokenDetails.role);
    let url =
      "https://spa-insurance.forklyft.in/v1/dealer//get-certificate-pagination-unpaid-admin?page=" +
      page;

    const response = await fetch(url, {
      // Adding method type
      method: "GET",

      // Adding headers to the request
      headers: {
        Authorization: "Bearer " + tokenDetails.token,
        "Content-type": "application/json",
      },
    });
    const resp = await response.json();
    if (resp.status == "200") {
      setCertificateData(resp.message);
      // console.log(resp)
      let pageArr = [];
      for (let num = 1; num < resp.total_certificate_count / 10 + 1; num++) {
        pageArr.push(num);
      }
      // console.log(pageArr)
      setTotalPages(pageArr);
    }
  };

  const generateOrder = async (certificateList) => {
    let tokenDetails = getTokenDetails();
    try {
      const result = await fetch(
        "https://spa-insurance.forklyft.in/v1/dealer/create-phone-pay-order",
        {
          // Adding method type
          method: "POST",

          // Adding body or contents to send
          body: JSON.stringify({
            certificates: payAll ? ["all"] : certificateList,
            phoneNumber: "",
          }),

          // Adding headers to the request
          headers: {
            Authorization: "Bearer " + tokenDetails.token,
            "Content-Type": "application/json",
          },
        }
      );

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }
      const resp = await result.json();
      console.log(resp, "resp23");
      return resp.message;
    } catch (error) {}
  };

  const submitHandler = async (payAllFlag) => {
    const ele = document.getElementsByClassName("selected");
    console.log(payAllFlag);
    const certificateList = payAllFlag ? ["all"] : [];

    if (!payAllFlag) {
      for (let index = 0; index < ele.length; index++) {
        const element = ele[index].checked;
        if (element) {
          certificateList.push(ele[index].value);
        }
      }
    }

    const list = await generateOrder(certificateList);

    navigate("/payment/paynow", { state: { list: list } });
  };

  const handleBulk = (e) => {
    const ele = document.getElementsByClassName("selected");

    for (let index = 0; index < ele.length; index++) {
      if (e.target.checked) {
        setPayAll(true);
        ele[index].checked = true;
      } else {
        ele[index].checked = false;
        setPayAll(false);
      }
    }
  };

  return (
    <div className=" w-[80%]">
      <h1 className="text-left text-2xl font-semibold ">Outstanding Payment</h1>

      <div className="w-full bg-[#fff] flex-wrap flex mt-[2%] pt-[1%] px-4">
        <div className="w-full flex justify-between gap-8 items-center pr-8 border-b-2 pb-2 ">
          <h2>Unpaid Certificates</h2>

          
            <div className="flex gap-4">
              <button
                onClick={() => {
                  submitHandler(true);
                }}
                className="bg-[var(--primary)]  text-[#fff] rounded-xl py-2 px-3 text-md"
              >
                Pay All
              </button>

              <button
                onClick={() => {
                  submitHandler(false);
                }}
                className="bg-[var(--primary)]  text-[#fff] rounded-xl py-2 px-3 text-md"
              >
                Proceed
              </button>
            </div>
          
        </div>
        <div className="w-full mx-4 mt-10">
          <div className="flex">
            <ul className="certificate_table flex w-full justify-start gap-[2%]  items-center mb-4 text-[#646c9a] font-medium text-md">
              <li>
                <input
                  onChange={handleBulk}
                  className=" w-full"
                  type="checkbox"
                />
              </li>
              <li className="cert__no">Certificate Number</li>
              <li>Name</li>
              <li>Plan</li>

              <li>Dealer Name</li>
              <li>Created On</li>

              <li>Amount</li>
            </ul>
          </div>

          {certificateData?.length > 0 ? (
            <>
              {certificateData.map((data, key) => (
                <div className="flex items-center border-b-2 border-[#f0f3ff]">
                  <div className="w-full">
                    <ul className="certificate_table flex w-full justify-start gap-[2%]  items-center py-4 ">
                      <li>
                        <input
                          disabled={payAll && true}
                          value={data.certificate_number}
                          className="selected w-full"
                          type="checkbox"
                        />
                      </li>
                      <li className="cert__no">{data.certificate_number}</li>
                      <li className="font-semibold">
                        {data.customerDetailsId.first_name +
                          " " +
                          data.customerDetailsId.last_name}
                      </li>
                      <li>{data.planDetailsId.plan}</li>

                      {data.dealerDetailsId === null ? (
                        <li className="text-[#212529] px-2 rounded-xl bg-[#e1e1ef]">
                          {data.adminDetailsId.first_name}
                        </li>
                      ) : (
                        <li className="text-[#212529] px-2 rounded-xl bg-[#e1e1ef]">
                          {data.dealerDetailsId.business_name}
                        </li>
                      )}

                      <li>{data.planDetailsId.start_date}</li>

                      <li>{data.planDetailsId.plan_amount}</li>
                    </ul>
                  </div>
                </div>
              ))}

              <div className=" flex justify-start gap-7 text-xl px-12 text-left my-6">
                <button
                  onClick={() => {
                    setPage(page - 1);
                  }}
                  disabled={page === 1 && true}
                  className={`text-2xl fa fa-chevron-left cursor-pointer p-3 text-center rounded-[10px] bg-[#f0f3ff] text-[#93a2dd] `}
                ></button>

                {totalPages &&
                  totalPages.length > 0 &&
                  totalPages.map((pages, key) => (
                    <span
                      onClick={() => {
                        setPage(pages);
                      }}
                      key={key}
                      className={`cursor-pointer text-xl ${
                        pages === page
                          ? "bg-[var(--primary)] text-[#fff]"
                          : "bg-[#fff] "
                      }  p-4  min-w-[3%] min-h-[3%] rounded-[10px] text-center`}
                    >
                      {pages}
                    </span>
                  ))}

                <button
                  onClick={() => {
                    setPage(page + 1);
                  }}
                  disabled={page === totalPages.length && true}
                  className="text-2xl fa fa-chevron-right cursor-pointer p-3 text-center rounded-[10px] bg-[#f0f3ff] text-[#93a2dd]"
                ></button>
              </div>
            </>
          ) : (
            <h1 className="my-4 text-2xl">No Results Found!</h1>
          )}
        </div>
      </div>

      <div className="text-[#646c9a] py-8 text-left">
        2024 © Powered by Across Assist
      </div>
    </div>
  );
};

export default OutstandingPaymentAdmin;
