import React, { useEffect } from "react";
import { getTokenDetails } from "../../utils/auth";

import { useNavigate } from "react-router-dom";
const PaymentStatus = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  let merchantTransactionId = queryParameters.get("merchantTransactionId");
  useEffect(() => {
    checkStatus();
  }, []);

  async function checkStatus() {
    let tokenDetails = getTokenDetails();

    try {
      const result = await fetch(
        "https://spa-insurance.forklyft.in/v1/dealer/pay-status-phone-pay",
        {
          // Adding method type
          method: "POST",

          // Adding body or contents to send
          body: JSON.stringify({
            merchantTransactionId: merchantTransactionId,
          }),

          // Adding headers to the request
          headers: {
            Authorization: "Bearer " + tokenDetails.token,
            "Content-Type": "application/json",
          },
        }
      );

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }
      const resp = await result.json();

      console.log(resp, "resp23");
      alert(resp.message);
      navigate("/");
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  }
  return <div></div>;
};

export default PaymentStatus;
