import React, { useState , useContext } from 'react'
import AuthContext from '../store/auth-context';
import { useNavigate } from 'react-router-dom';
import { getTokenDetails } from '../utils/auth';



const NewDealer = () => {
    const context = useContext(AuthContext);
    const navigate =useNavigate()

    const [data, setData] = useState({
        "business_name": "",
        "mobile_number": "",
        "dealer_code": "",
        "gst_treatment": "",
        "pan": "",
        "bank_name": "",
        "bank_account_number": "",
        "bank_ifsc": "",
        "password": "",

        "gst_number": "",

        "email": "",

        "address_line_1": "",
        "address_line_2": "",

    });


    const handleChange = (e) => {
        let value = e.target.value
        let ele = document.getElementsByName(e.target.name)[0].parentNode;
        let errorText = ele.querySelector('.error-text')
        if (value !== '' && errorText) {
            errorText && ele.removeChild(errorText)
        }
        setData({
            ...data,
            [e.target.name]: value
        });

     
    }



    function validateForm() {
        let fields = Object.keys(data)
        let flag = true
        for (const field of fields) {
            let isReq = document.getElementsByName(field)[0].getAttribute('required')
            if (data[field] === "" && isReq !== null) {
                let ele = document.getElementsByName(field)[0].parentNode;
               
                if (!ele.querySelector('.error-text') ) {
                    console.log(data[field])

                    let label = ele.parentNode.firstChild.innerHTML
                    const node = document.createElement("p");
                    node.innerHTML = label + " is Required"
                    node.classList.add('text-[red]', 'error-text')
                    ele.appendChild(node)

                }
                flag = false

            }
        }
        return flag

    }


    const createDealer = async () => {
        let validation = validateForm()
        console.log({validation})


        if (validation){
            let tokenDetails = getTokenDetails()

            try {


                const response = await fetch("https://spa-insurance.forklyft.in/v1/auth/register", {

                    // Adding method type
                    method: "POST",

                    // Adding body or contents to send
                    body: JSON.stringify(
                        data
                    ),

                    // Adding headers to the request
                    headers: {
                        'Authorization': 'Bearer ' + tokenDetails.token,
                        'Content-Type': 'application/json'
                    }
                }
                )
                const resp = await response.json();
                if (resp.status === 201){
                    alert(resp.message)
                    navigate('/')

                }else{
                    alert(resp.message)
                }
                console.log(resp)
               

            } catch (error) {
                console.log(error)

            }
            




    }

    }


    return (
        <div className='w-[52%] mx-4'>
            <h2 className='text-[#000] font-semibold text-2xl text-left'>Dealer Details</h2>
            <div className=' w-full flex mt-[5%] justify-between flex-wrap gap-y-[1.5rem] bg-[#fff] rounded-sm px-8 py-10'>
                <div className="form-group  text-left">
                    <label>Business Name</label>
                    <div className="">
                        <input type="text" name='business_name' onChange={handleChange} value={data.business_name} required />

                    </div>
                </div>

               

            

                <div className="form-group w-[45%] text-left">
                    <label>Mobile Number</label>
                    <div className="custom-input">
                        <span>+91</span>
                        <input type="text" name='mobile_number' onChange={handleChange} value={data.mobile_number} required />
                    </div>
                </div>

                <div className="form-group  text-left">
                    <label>Address Line 1</label>
                    <div className="">
                        <input type="text" name='address_line_1' onChange={handleChange} value={data.address_line_1} />

                    </div>
                </div>

                <div className="form-group  text-left">
                    <label>Address Line 2</label>
                    <div className="">
                        <input type="text" name='address_line_2' onChange={handleChange} value={data.address_line_2} />

                    </div>
                </div>



                <div className="form-group  text-left">
                    <label>Dealer Code</label>
                    <div className="">
                        <input type="text" placeholder='' name='dealer_code' onChange={handleChange} value={data.dealer_code} />

                    </div>
                </div>


                <div className="form-group  text-left">
                    <label>GST Treatment</label>
                    <div className="">
                        <input type="text" placeholder='' name='gst_treatment' onChange={handleChange} value={data.gst_treatment} />

                    </div>
                </div>


                <div className="form-group  text-left">
                    <label>GST Number</label>
                    <div className="">
                        <input type="text" placeholder='' name='gst_number' onChange={handleChange} value={data.gst_number} />

                    </div>
                </div>

                <div className="form-group  text-left">
                    <label>Pan</label>
                    <div className="">
                        <input type="text" placeholder='PAN Number' name='pan' onChange={handleChange} value={data.pan} />

                    </div>
                </div>

                <div className="form-group  text-left">
                    <label>Bank Name</label>
                    <div className="">
                        <input type="text" placeholder='Bank Name' name='bank_name' onChange={handleChange} value={data.bank_name} />

                    </div>
                </div>

                <div className="form-group  text-left">
                    <label>Bank Account Number</label>
                    <div className="">
                        <input type="number" placeholder='' name='bank_account_number' onChange={handleChange} value={data.bank_account_number} />

                    </div>
                </div>

                <div className="form-group  text-left">
                    <label>Bank IFSC</label>
                    <div className="">
                        <input type="text" placeholder='' name='bank_ifsc' onChange={handleChange} value={data.bank_ifsc} />

                    </div>
                </div>

                <div className="form-group  text-left">
                    <label>Email <span className='text-sm'>(to be used as login username)</span></label>
                    <div className="">
                        <input type="email" name='email' onChange={handleChange} value={data.email} required />

                    </div>
                </div>

                <div className="form-group  text-left">
                    <label>Password <span className='text-sm'>(to be used as login password)</span></label>
                    <div className="">
                        <input type="password" name='password' onChange={handleChange} value={data.password} required/>

                    </div>
                </div>

                <button onClick={createDealer} className='px-8 py-3 mt-8 rounded-[5px] text-white bg-[var(--primary)] text-xl'>Submit</button>



            </div>

          

            


           



            <div>

            </div>
        </div>
    )
}

export default NewDealer