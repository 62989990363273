import { Link } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import React, { useEffect, useState, useContext } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import Pdf from "../../../components/pdf";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays, format } from "date-fns";
import axios from "axios";
import * as XLSX from "xlsx/xlsx.mjs";
import { getTokenDetails } from "../../../utils/auth";

const ViewCertificate = () => {
  const context = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [generate, setGenerate] = useState();
  const [triggerDatePicker, setTriggerDatePicker] = useState(false);
  const [filter, setFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [totalPages, setTotalPages] = useState([]);
  const [certificateData, setCertificateData] = useState(null);
  const [triggerExportDatePicker, setTriggerExportDatePicker] = useState(false);
  const [exportDate, setExportDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  useEffect(() => {
    getCertificates();
  }, [page]);

  const getCertificates = async () => {
    let tokenDetails = getTokenDetails();

    let url;
    if (filter === "policyDate") {
      url = `https://spa-insurance.forklyft.in/v1/dealer/get-certificate-pagination?page=${page}&startDate=${format(
        new Date(state[0].startDate),
        "dd-MM-yyyy"
      )}&endDate=${format(new Date(state[0].endDate), "dd-MM-yyyy")}}`;
    } else if (filter !== "") {
      url = `https://spa-insurance.forklyft.in/v1/dealer/get-certificate-pagination?page=${page}&${
        filter + "=" + searchTerm
      }`;
    } else {
      url = `https://spa-insurance.forklyft.in/v1/dealer/get-certificate-pagination?page=${page}`;
    }

    const response = await fetch(url, {
      // Adding method type
      method: "GET",

      // Adding headers to the request
      headers: {
        Authorization: "Bearer " + tokenDetails.token,
        "Content-type": "application/json",
      },
    });
    const resp = await response.json();
    if (resp.status == "200") {
      setCertificateData(resp.message);
      console.log(resp);
      let pageArr = [];
      for (let num = 1; num < resp.total_certificate_count / 10 + 1; num++) {
        pageArr.push(num);
      }
      // console.log(pageArr)
      setTotalPages(pageArr);
    }
  };

  const exportDateHandler = (item) => {
    if (item[0].startDate !== item[0].endDate) {
      setTriggerExportDatePicker(false);
    } else if (item[0].startDate === "" && item[0].endDate === "") {
      setTriggerExportDatePicker(false);
    }
    setExportDate(item);

    console.log(item);
  };

  const dateHandler = (item) => {
    if (item[0].startDate !== item[0].endDate) {
      setTriggerDatePicker(false);
    } else if (item[0].startDate === "" && item[0].endDate === "") {
      setTriggerDatePicker(false);
    }
    setState(item);

    console.log(item);
  };

  const downloadExcel = async () => {
    let { token } = getTokenDetails();

    let duration = "";
    // console.log(duration)
    try {
      let data = JSON.stringify({
        type: duration,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://spa-insurance.forklyft.in/v1/dealer/bulk-download",
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      let response = await axios.request(config);

      let resp = response.data;
      console.log(resp, "#check");
      let sorted = [];
      for (let index = 0; index < resp.length; index++) {
        let record = resp[index];

        let data = {
          "Issued Date": record.planDetailsId.start_date,
          "Certificate Number": record.certificate_number,
          // "Customer Code": " ",
          "Customer Name":
            record.customerDetailsId.first_name +
            " " +
            record.customerDetailsId.last_name,
          "Customer Email": " ",
          "Customer MobileNumber": record.customerDetailsId.mobile_number,
          Locality: record.customerDetailsId.address_line_1,
          City: record.customerDetailsId.city,
          State: record.customerDetailsId.state,
          "Brand Name": record.vehicleDetailsId.make,
          Model: record.vehicleDetailsId.model,
          Variant: record.vehicleDetailsId.variant,
          "Vehicle Type": record.vehicleDetailsId.vehicle_type,
          // "Fuel Type": " ",
          // "Model Year": " ",
          // "Manufacturing Year": " ",
          "Registration Number": record.vehicleDetailsId.registration_number,
          // "Registration Type": " ",
          // "Registration Date": " ",
          // "Registration Validity": " ",
          "Vehicle Status": record.vehicleDetailsId.vehicle_status,
          "Chasis Number": record.vehicleDetailsId.chasis_number,
          "Engine Number": record.vehicleDetailsId.engine_number,
          "Plan Name": record.planDetailsId.plan,
          "Start Date": record.planDetailsId.start_date,
          "End Date": record.planDetailsId.end_date,
          Price: record.planDetailsId.plan_amount,
          "Tax Rate": "18%",
          "Premium Amount": record.planDetailsId.total_amount,
          // "Dealer Code": record.dealerDetailsId?.dealer_code,
          "Dealer Name": record.dealerDetailsId?.business_name,
          // "Sub Dealer Name": " ",
          // "Partner Name": " ",
          // "Created By": " ",
          // "Dealer Commission Percentage": " ",
          // "Dealer Commission Amount": " ",
          // "Dealer Payment Status": " ",
          // "Dealer Payment Date": " ",
          // "Dealer Payment Id": " ",
          // "Online Transaction Id": " ",
          // "Invoice Number": " ",
          // "Invoice Date": " ",
          // "Payout Ref No": " ",
          // "Payout Transaction Date": " ",
          "Nominee Name": record.customerDetailsId.nominee_name,
          "Nominee RelationShip": record.customerDetailsId.nominee_relation,
          "Nominee DOB": record.customerDetailsId.nominee_dob,
          "Nominee Gender": record.customerDetailsId.nominee_gender,
        };
        sorted.push(data);

        const element = resp[index];
      }

      handleConvert(sorted);
    } catch (error) {
      console.log(error);
      // handleDownload()
    }
  };

  const handleConvert = (jsonData) => {
    try {
      // Parse the XML data (assuming it's in a valid XML format)

      // Create a worksheet and add data
      const ws = XLSX.utils.json_to_sheet(jsonData);

      // Create a new workbook and add the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      // Generate the Excel file
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

      // Set the Excel data in state
      handleDownload(excelBuffer);
    } catch (error) {
      console.error("Error converting XML to Excel:", error);
    }
  };

  const handleDownload = (excelData) => {
    if (excelData) {
      // Create a Blob from the Excel data and create a download link
      const blob = new Blob([excelData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Certificates_.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  function convertDateFormat(inputDate) {
    // Parse the input date string
    var dateObject = new Date(inputDate);

    // Extract day, month, and year components
    var day = dateObject.getDate();
    var month = dateObject.getMonth() + 1; // Months are zero-indexed, so add 1
    var year = dateObject.getFullYear();

    // Format the components with leading zeros if needed
    var formattedDay = day < 10 ? "0" + day : day;
    var formattedMonth = month < 10 ? "0" + month : month;

    // Construct the formatted date string in "dd-mm-yyyy" format
    var formattedDate = formattedDay + "-" + formattedMonth + "-" + year;

    return formattedDate;
  }

  return (
    <div className=" w-[80%]">
      <h1 className="text-left text-2xl font-semibold ">Certificates</h1>

      <div className="w-full bg-[#fff] flex-wrap flex mt-[2%] pt-[5%] px-4">
        <div className="w-full flex justify-start gap-8 items-center pr-8">
          <div className="w-[15%]">
            <select
              className="border-2 p-2 rounded-xl"
              value={filter}
              name="vehicle_type"
              onChange={(e) => {
                setFilter(e.target.value);
                setSearchTerm("");
              }}
              id="input--filter"
              tabIndex="-98"
            >
              <option value="" hidden>
                Filter by
              </option>
              <option value="customerName">Customer Name</option>
              <option value="mobileNumber">Mobile Number</option>
              <option value="certificateNumber">Certificate Number</option>
              <option value="policyDate">Policy Date</option>
              <option value="">Get All</option>
            </select>
          </div>

          {filter === "policyDate" ? (
            <div className=" relative w-[30%] flex justify-start items-center gap-4">
              {/* <label htmlFor="">Policy Date</label> */}
              <input
                value={
                  format(new Date(state[0].startDate), "dd-MM-yyyy") +
                  "  -  " +
                  format(new Date(state[0].endDate), "dd-MM-yyyy")
                }
                onFocus={() => {
                  setTriggerDatePicker(true);
                }}
                className="border-2 w-full p-2"
                type="text"
                placeholder="Filter"
              />
              {triggerDatePicker && (
                <div className="absolute top-[100%] left-[-50%]">
                  <DateRangePicker
                    onChange={(item) => {
                      dateHandler([item.selection]);
                    }}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    direction="horizontal"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="w-[30%] flex justify-start items-center gap-4">
              <input
                className="border-2 w-full p-2"
                value={searchTerm}
                type="text"
                placeholder="Search ..."
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </div>
          )}

          <button
            className="bg-[var(--primary)]  text-[#fff] rounded-xl py-2 px-3 text-md"
            onClick={getCertificates}
          >
            Search
          </button>

          <div className=" relative w-[50%] flex justify-start items-center gap-4">
            <label htmlFor="">Policy Date</label>
            <input
              value={
                format(new Date(exportDate[0].startDate), "dd-MM-yyyy") +
                "  -  " +
                format(new Date(exportDate[0].endDate), "dd-MM-yyyy")
              }
              onFocus={() => {
                setTriggerExportDatePicker(true);
              }}
              className="border-2 w-[40%] p-2"
              type="text"
              placeholder="Filter"
            />
            {triggerExportDatePicker && (
              <div className="absolute top-[100%] left-[-50%]">
                <DateRangePicker
                  onChange={(item) => {
                    exportDateHandler([item.selection]);
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={exportDate}
                  direction="horizontal"
                />
              </div>
            )}
          </div>

          <button
            onClick={downloadExcel}
            className="bg-[var(--primary)]  text-[#fff] rounded-xl py-2 px-3 text-md"
          >
            Export
          </button>
        </div>
        <div className="w-full mx-4 mt-10">
          <div className="flex">
            <ul className="certificate_table flex w-[90%] justify-start gap-[2%]  items-start mb-4 text-[#646c9a] font-medium text-md">
              <li className="cert__no">Certificate Number</li>
              <li>Name</li>
              <li>Plan</li>
              <li>Amount Paid</li>
              <li>Dealer Name</li>
              <li>Created On</li>
              <li>Policy period</li>
            </ul>
            <p className="text-[1vw] text-[#646c9a] text-left font-semibold">
              Download
            </p>
          </div>

          {certificateData?.length > 0 ? (
            <>
              {certificateData.map((data, key) => (
                <div
                  key={key}
                  className="flex items-center border-b-2 border-[#f0f3ff]"
                >
                  <Link className="w-[90%]" to={"/view/" + data.id}>
                    <ul className="certificate_table flex w-full justify-start gap-[2%]  items-center py-4 ">
                      <li className="cert__no">{data.certificate_number}</li>
                      <li className="font-semibold">
                        {data.customerDetailsId.first_name +
                          " " +
                          data.customerDetailsId.last_name}
                      </li>
                      <li>{data.planDetailsId.plan}</li>
                      <li>{data.planDetailsId.plan_amount}</li>
                      {data.dealerDetailsId === null ? (
                        <li className="text-[#212529] px-2 rounded-xl bg-[#e1e1ef]">
                          {data.adminDetailsId.first_name}
                        </li>
                      ) : (
                        <li className="text-[#212529] px-2 rounded-xl bg-[#e1e1ef]">
                          {data.dealerDetailsId.business_name}
                        </li>
                      )}

                      <li>
                        {convertDateFormat(data.planDetailsId.start_date)}
                      </li>
                      <li>
                        {convertDateFormat(data.planDetailsId.start_date) +
                          "-" +
                          convertDateFormat(data.planDetailsId.end_date)}
                      </li>
                    </ul>
                  </Link>
                  <div className="">
                    {generate === data.id ? (
                      <PDFDownloadLink
                        className="bg-[var(--primary)]  text-[#fff] rounded-xl py-2 px-3 text-[0.4vw] 2xl:text-[0.5vw]"
                        document={<Pdf certId={data.id} />}
                        fileName={`${data.id}.pdf`}
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Ready for Download"
                        }
                      </PDFDownloadLink>
                    ) : (
                      <button
                        className="bg-[var(--primary)] text-[#fff] rounded-xl py-2 px-3 text-[0.6vw] "
                        onClick={() => setGenerate(data.id)}
                      >
                        Generate Pdf
                      </button>
                    )}
                  </div>
                </div>
              ))}

              <div className=" flex justify-start gap-7 text-xl px-12 text-left my-6">
                <button
                  onClick={() => {
                    setPage(page - 1);
                  }}
                  disabled={page === 1 && true}
                  className={`text-2xl fa fa-chevron-left cursor-pointer p-3 text-center rounded-[10px] bg-[#f0f3ff] text-[#93a2dd] `}
                ></button>

                {totalPages &&
                  totalPages.length > 0 &&
                  totalPages.map((pages, key) => (
                    <span
                      onClick={() => {
                        setPage(pages);
                      }}
                      key={key}
                      className={`cursor-pointer text-xl ${
                        pages === page
                          ? "bg-[var(--primary)] text-[#fff]"
                          : "bg-[#fff] "
                      }  p-4  min-w-[3%] min-h-[3%] rounded-[10px] text-center`}
                    >
                      {pages}
                    </span>
                  ))}

                <button
                  onClick={() => {
                    setPage(page + 1);
                  }}
                  disabled={page === totalPages.length && true}
                  className="text-2xl fa fa-chevron-right cursor-pointer p-3 text-center rounded-[10px] bg-[#f0f3ff] text-[#93a2dd]"
                ></button>
              </div>
            </>
          ) : (
            <h1 className="my-4 text-2xl">No Results Found!</h1>
          )}
        </div>
      </div>
      {/* 
          <PDFViewer className='w-full h-full' >
              <Pdf certId={'29534453-d410-4bbd-9482-88a8277666d6'}/>
  </PDFViewer> */}

      <div className="text-[#646c9a] py-8 text-left">
        2024 © Powered by Across Assist
      </div>
    </div>
  );
};

export default ViewCertificate;
