import React from 'react'
import image from '../images/aa_logo_horizontal_small.webp'
import { Link } from 'react-router-dom'
const Header = () => {
  return (
    <div className='bg-[#fff]'>

          <nav className="bg-[#F8F9FA]">
              <div   className="py-2">
                  <div className="flex w-full gap-[10%] justify-center text-[#6c757d]">
                      <div   className="  text-center text-md">
                          <a   href="https://www.facebook.com/acrossassist" className="text-muted m-1 link-social link-facebook">
                              <i   className="fa fa-facebook-square fa-lg"></i>
                          </a>
                          <a   href="https://www.twitter.com/acrossassist" className="text-muted m-1 link-social  link-twitter">
                              <i   className="fa fa-twitter-square fa-lg"></i>
                          </a>
                          <a   href="https://www.linkedin.com/company/acrossassist" className="text-muted m-1 link-social link-linkedin">
                              <i   className="fa fa-linkedin fa-lg"></i>
                          </a>
                      </div>
                      <div   className=" text-center text-md">
                          <a   href="https://apps.apple.com/us/app/bharti-assist-global/id1534996856" className="text-muted m-1 link-app link-appstore">
                              <i   className="fa fa-apple fa-lg"></i> App Store
                          </a>
                          <a   href="https://play.google.com/store/apps/details?id=in.kaaryaa.bhartiassist.customerApp" className="text-muted m-1 link-app  link-playstore">
                              <i   className="fa fa-google-play fa-lg"></i> Play Store
                          </a>
                      </div>
                      <div   className="  text-center text-md">
                          <a   href="mailto:contact@acrossassist.com" className="text-muted m-1 link-app  link-playstore">
                              <i   aria-hidden="true" className="fa fa-mail-bulk"></i> contact@acrossassist.com
                          </a>
                      </div>
                      <div   className=" text-center text-md">
                          <a   href="tel:+911204501400" className="text-muted m-1 link-app  link-playstore">
                              <i   aria-hidden="true" className="fa fa-phone-alt"></i> +91-120-450-1400
                          </a>
                      </div>
                  </div>
              </div>
          </nav>




          <nav   className="bh-[#fff] flex">
              <div   className="container flex w-[80%] my-6">
                  <a className="navbar-brand position-relative" href="/">
                      <img src={image} className='w-[70%]' alt="Across Assist"  />
                     
                          <small    className="position-absolute">(Formerly Bharti Assist Global)</small>
                  </a>
               
                  <div   className="flex w-[70%] justify-between">
                      <ul   className="w-[70%] flex justify-center gap-[5%] text-[var(--primary)] font-thin items-center">
                         
                          <li   className="">
                              <a   className="" href="/" >
                                  <strong  >SOLUTIONS</strong>
                              </a>
                            
                          </li>
                          <li   className="">
                              <a className="nav-link text-dark-blue" href="/about">
                                  <strong  >ABOUT US</strong>
                              </a>
                          </li>
                          <li   className="">
                              <a className="nav-link text-dark-blue" href="/team">
                                  <strong  >TEAM</strong>
                              </a>
                          </li>
                          <li   className="">
                              <a className="nav-link text-dark-blue" href="/faqs">
                                  <strong  >FAQS</strong>
                              </a>
                          </li>
                          <li   className="">
                              <a className="nav-link text-dark-blue" href="/contact">
                                  <strong  >CONTACT US</strong>
                              </a>
                          </li>
                      </ul>
                      <Link to="/admin" className="flex bg-[#1362DF] rounded-xl justify-center items-center h-[fit-content] p-4 self-center text-[#fff] gap-4">
                          <i   className="fa fa-user-circle " ></i>
                          LOGIN
                      </Link>

                  </div>
              </div>
          </nav>


    </div>
  )
}

export default Header