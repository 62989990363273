import React, { useState, useEffect, Children } from "react";

import AuthContext from "../store/auth-context";

import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";

import { authenticate } from "../utils/auth";

function Layout() {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState({ status: false, token: null });
  const [role, setRole] = useState("");

  // const loginHandler = (status, token) => {
  //     // console.log(status,token)
  //     setIsLoggedIn({ status: status, token: token })
  //     localStorage.setItem('token', token)
  //     navigate('/')
  // }

  // const roleHandler = (role) => {
  //     console.log(role)
  //     setRole(role)
  // }

  return (
    <>
      <div className="App sm:mx-14 flex gap-8 mt-[3%] justify-start">
        <Sidebar />
        <Outlet />
      </div>
    </>
  );
}

export default Layout;
