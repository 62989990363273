import React, { useEffect, useContext, useState } from "react";
import AuthContext from "../store/auth-context";
import { getTokenDetails } from "../utils/auth";
const DealerOutstanding = () => {
  const context = useContext(AuthContext);
  const [dealerData, setDealerData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);

  useEffect(() => {
    getDealersOutstanding();
  }, []);

  const getDealersOutstanding = async () => {
    let { token } = getTokenDetails();
    let url =
      "https://spa-insurance.forklyft.in/v1/dealer/get-unpaid-dealers/?page=" +
      page;

    const response = await fetch(url, {
      // Adding method type
      method: "GET",

      // Adding headers to the request
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    });
    const resp = await response.json();
    if (resp.status == "200") {
      setDealerData(resp.message);
      console.log(resp);
      let pageArr = [];
      for (let num = 1; num < resp.total_certificate_count / 10 + 1; num++) {
        pageArr.push(num);
      }
      // console.log(pageArr)
      setTotalPages(pageArr);
    }
  };

  return (
    <div className=" w-[80%]">
      <h1 className="text-left text-2xl font-semibold ">Dealer Outstanding</h1>

      <div className="w-full bg-[#fff] flex-wrap flex mt-[2%] pt-[1%] px-4">
        <div className="w-full mx-4 mt-10">
          <div className="flex">
            <ul className="certificate_table flex w-full justify-start gap-[2%]  items-center mb-4 text-[#646c9a] font-medium text-md">
              <li>Dealer Name</li>
              <li>Dealer Email</li>

              <li>Certificate count</li>
              <li>Amount Due</li>
            </ul>
          </div>

          {dealerData?.length > 0 ? (
            <>
              {dealerData.map((data, key) => (
                <div
                  key={key}
                  className="flex items-center border-b-2 border-[#f0f3ff]"
                >
                  <div className="w-full">
                    <ul className="certificate_table flex w-full justify-start gap-[2%]  items-center py-4 ">
                      <li className="font-semibold">{data.dealer_name}</li>
                      <li>{data.dealer_email}</li>

                      <li>{data.total_certificate_count}</li>

                      <li>{data.total_amount_due}</li>
                    </ul>
                  </div>
                </div>
              ))}

              {totalPages && totalPages.length > 0 && (
                <div className=" flex justify-start gap-7 text-xl px-12 text-left my-6">
                  <button
                    onClick={() => {
                      setPage(page - 1);
                    }}
                    disabled={page === 1 && true}
                    className={`text-2xl fa fa-chevron-left cursor-pointer p-3 text-center rounded-[10px] bg-[#f0f3ff] text-[#93a2dd] `}
                  ></button>

                  {totalPages.map((pages, key) => (
                    <span
                      onClick={() => {
                        setPage(pages);
                      }}
                      key={key}
                      className={`cursor-pointer text-xl ${
                        pages === page
                          ? "bg-[var(--primary)] text-[#fff]"
                          : "bg-[#fff] "
                      }  p-4  min-w-[3%] min-h-[3%] rounded-[10px] text-center`}
                    >
                      {pages}
                    </span>
                  ))}

                  <button
                    onClick={() => {
                      setPage(page + 1);
                    }}
                    disabled={page === totalPages.length && true}
                    className="text-2xl fa fa-chevron-right cursor-pointer p-3 text-center rounded-[10px] bg-[#f0f3ff] text-[#93a2dd]"
                  ></button>
                </div>
              )}
            </>
          ) : (
            <h1 className="my-4 text-2xl">No Results Found!</h1>
          )}
        </div>
      </div>

      <div className="text-[#646c9a] py-8 text-left">
        2024 © Powered by Across Assist
      </div>
    </div>
  );
};

export default DealerOutstanding;
