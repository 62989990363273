import React , {useState , useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import Admin from './pages/admin';



import { createBrowserRouter , RouterProvider } from 'react-router-dom';

import IssueCertificate from './pages/dealer/certificate/new';

import ViewCertificate from './pages/dealer/certificate';
import AuthContext from './store/auth-context';

import Profile from './pages/profile';
import { useNavigate } from 'react-router-dom';
import CertificateId from './pages/dealer/certificate/certificateId';
import Upload  from './pages/upload'
import ContactUs from './pages/contactUs';
import AccountOverview from './pages/accountOverview';
import NewDealer from './pages/newDealer';
import GetDealers from './pages/getDealers';
import OutstandingPayment from './pages/payment/outstandingPayment';
import Paynow from './pages/payment/paynow';
import DealerOutstanding from './components/dealerOutstanding';
import Terms from './pages/terms-conditions';
import PrivacyPolicy from './pages/privacy-policy';
import Layout from './components/layout';
import { authenticate, authenticateAdmin } from './utils/auth';
import PaymentStatus from './pages/payment/payment-status';
import OutstandingPaymentAdmin from './pages/payment/outstandingPaymentAdmin';
import CreatePlan from './pages/create-plan';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <AccountOverview />, loader: authenticate },
      { path: "/new", element: <IssueCertificate />, loader: authenticate },
      { path: "/view", element: <ViewCertificate />, loader: authenticate },
      { path: "/view/:id", element: <CertificateId />, loader: authenticate },
      { path: "/profile", element: <Profile />, loader: authenticate },
      { path: "/upload", element: <Upload />, loader: authenticate },
      { path: "/contactUs", element: <ContactUs />, loader: authenticate },
      {
        path: "/payment/outstanding",
        element: <OutstandingPayment />,
        loader: authenticate,
      },
      { path: "/payment/paynow", element: <Paynow />, loader: authenticate },
      { path: "/newDealer", element: <NewDealer />, loader: authenticateAdmin },
      {
        path: "/getDealers",
        element: <GetDealers />,
        loader: authenticateAdmin,
      },
      {
        path: "/dealerOutstanding",
        element: <DealerOutstanding />,
        loader: authenticateAdmin,
      },
      {
        path: "/create-plan",
        element: <CreatePlan />,
        loader: authenticateAdmin,
      },
      {
        path: "payment/outstanding-admin",
        element: <OutstandingPaymentAdmin />,
        loader: authenticateAdmin,
      },
    ],
  },
  { path: "/admin", element: <Admin /> },
  { path: "/terms-conditions", element: <Terms /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  {
    path: "/phone-pay-redirect",
    element: <PaymentStatus />,
    loader: authenticate,
  },
]);

export const queryClient = new QueryClient();
function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );

}
   
  export default App;

   
 
  



