import React from "react";

const AuthContext = React.createContext({
    isLoggedIn: false,
    loginHandler: () => { },

    role: false,
    roleHandler: () => { },




});

export default AuthContext;