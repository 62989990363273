import React, { useState, useEffect, useContext } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import "../";
import AuthContext from "../store/auth-context";
import logo from "../images/accrossAssist.png";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  title: {
    fontSize: 7,
    backgroundColor: "#044c9c",
    fontWeight: "bold",
    color: "#fff",
    margin: "4 0",
    padding: "3 1",
  },
  content: {
    fontSize: 8,
    color: "#044c9c",
    fontWeight: "bold",
    margin: "20 0",
  },
  content_2: {
    fontSize: 6,
    color: "#000",
    fontWeight: "bold",
    margin: "1 0 4",
  },
  table: {
    display: "table",
    width: "100%",

    borderStyle: "solid",
    borderWidth: 0,
    borderColor: "#fff",
    borderCollapse: "collapse",
    marginLeft: "6.70033pt",
    borderTop: "2 solid #044c9c",
    borderBottom: "2 solid #044c9c",
    tableLayout: "fixed",
  },
  row: {
    
    flexDirection: "row",
  },
  cell: {
    width: "25%",
    

    // borderStyle: 'solid',
    borderWidth: "0",
    
    padding: 1,
  },
  text: {
    fontSize: 8,
    pading: "2 0",
    // textAlign: 'left',
    // paddingLeft: 2,
    color: "#000",
  
  },
  headerCell: {
    backgroundColor: "#DCDCDC",
  },
  headerText: {
    fontWeight: "bold",
  },
  addressContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    width: "100%",
    marginBottom: 5,
  },
  address_div: {
    width: "40%",
  },
});

function numberToRupeesWords(amount) {
  const ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  function convertTwoDigitNumber(num) {
    if (num < 10) {
      return ones[num];
    } else if (num < 20) {
      return teens[num - 11];
    } else {
      const digitOne = num % 10;
      const digitTen = Math.floor(num / 10);
      return tens[digitTen] + (digitOne ? " " + ones[digitOne] : "");
    }
  }

  function convertThreeDigitNumber(num) {
    const digitHundred = Math.floor(num / 100);
    const remainingTwoDigits = num % 100;
    return (
      (digitHundred ? ones[digitHundred] + " Hundred" : "") +
      (remainingTwoDigits
        ? " " + convertTwoDigitNumber(remainingTwoDigits)
        : "")
    );
  }

  function convertToWordsRecursive(num) {
    if (num < 100) {
      return convertTwoDigitNumber(num);
    } else {
      const digitThousand = Math.floor(num / 1000);
      const remainingThreeDigits = num % 1000;
      return (
        (digitThousand
          ? convertToWordsRecursive(digitThousand) + " Thousand"
          : "") +
        (remainingThreeDigits
          ? " " + convertThreeDigitNumber(remainingThreeDigits)
          : "")
      );
    }
  }

  function convertToRupeesWords(mainAmount) {
    if (!mainAmount) {
      return "Zero Rupees";
    } else {
      const parts = mainAmount.toString().split(".");
      const rupees = parseInt(parts[0]);
      const paise = parseInt(parts[1]) || 0;

      const rupeesInWords =
        convertToWordsRecursive(rupees) + (rupees === 1 ? " Rupee" : " Rupees");
      const paiseInWords =
        convertToWordsRecursive(paise) + (paise === 1 ? " Paise" : " Paise");

      return rupeesInWords + (rupees && paise ? " and " : "") + paiseInWords;
    }
  }

  return convertToRupeesWords(amount);
}

const Pdf = ({ certId }) => {
  console.log(certId);

  const context = useContext(AuthContext);

  const [certificateDetails, setCertificateDetails] = useState(null);

  useEffect(() => {
    getCertificateDetails(certId);
  }, []);

  const getCertificateDetails = async (id) => {
    const response = await fetch(
      "https://spa-insurance.forklyft.in/v1/dealer/get-certificate?id=" + id,
      {
        // Adding method type
        method: "GET",

        // Adding headers to the request
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-type": "application/json",
        },
      }
    );
    const resp = await response.json();
    resp.status == 200 && setCertificateDetails(resp.message);

    console.log(resp);
  };

  if (certificateDetails === null) {
    return (
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.heading}>Certificate Details</Text>
        </View>
      </Page>
    );
  }

  function convertDateFormat(inputDate) {
    // Parse the input date string
    var dateObject = new Date(inputDate);

    // Extract day, month, and year components
    var day = dateObject.getDate();
    var month = dateObject.getMonth() + 1; // Months are zero-indexed, so add 1
    var year = dateObject.getFullYear();

    // Format the components with leading zeros if needed
    var formattedDay = day < 10 ? "0" + day : day;
    var formattedMonth = month < 10 ? "0" + month : month;

    // Construct the formatted date string in "dd-mm-yyyy" format
    var formattedDate = formattedDay + "-" + formattedMonth + "-" + year;

    return formattedDate;
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image style={{ width: 20, textAlign: "center" }} src={logo} />
        </View>
        <Text
          style={{
            ...styles.content_2,
            fontSize: "6",
            fontWeight: "900",
            textAlign: "center",
          }}
        >
          Powered by AcrossAssist Private Limited
        </Text>
        <View
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            flexDirection: "row",
            gap: "14vw",
          }}
        >
          <Text style={{ ...styles.content_2 }}>
            Unit No. 3rd Floor, Plot No. 98, Gurugram, Sector 44,Haryana, 122003
          </Text>
          <Text style={{ ...styles.content_2 }}>GST NO.- 06AAHCB4822B1Z6</Text>
        </View>

        <Text style={{ ...styles.content_2, textAlign: "center" }}>
          In case of any queries/assistance, please call uson 0120-450-1405 or
          write to uson customer.service@acrossassist.com
        </Text>

        <Text style={styles.title}> Certificate Cum Invoice</Text>
        <Text style={{ ...styles.content_2 }}>Tax Invoice</Text>

        <Text style={{ ...styles.content_2, textAlign: "right" }}>
          Name of the Service Provider : Across Assist Private Limited
        </Text>

        <Text style={{ ...styles.content_2 }}>
          This is to certify that Vehicle with the following detailsiscovered
          under Assistance Program, asper the detailsdefined under benefits,
          termsand condition of the program
        </Text>

        <View style={styles.table}>
          <View style={styles.row}>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Certificate Number
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.certificate_number}
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Intermediary Name
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                :{" "}
                {certificateDetails.dealerDetailsId
                  ? certificateDetails.dealerDetailsId.business_name
                  : certificateDetails.adminDetailsId.first_name}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Certificate Start Date
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                :{" "}
                {convertDateFormat(certificateDetails.planDetailsId.start_date)}
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Certificate End Date
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                :{convertDateFormat(certificateDetails.planDetailsId.end_date)}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Engine Number
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                {certificateDetails.vehicleDetailsId.engine_number}
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Chasis Number
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.vehicleDetailsId.chasis_number}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Manufacturer
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.vehicleDetailsId.make}
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Model
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.vehicleDetailsId.model}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Color
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                :{certificateDetails.vehicleDetailsId.color}
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Registration Number
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.vehicleDetailsId.registration_number}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Plan Name
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.planDetailsId.plan_name}
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Plan Amount
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.planDetailsId.plan_amount}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                GST(18)
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                :{" "}
                {String(
                  (Number(certificateDetails.planDetailsId.plan_amount) * 18) /
                    100
                )}
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Total Amount(including Tax)
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.planDetailsId.total_amount}
              </Text>
            </View>
          </View>

          <View style={{ ...styles.row }}>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                GST No. of Service Recipient
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>: NA</Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Amount in words
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                :{" "}
                {numberToRupeesWords(
                  certificateDetails.planDetailsId.total_amount
                )}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Place of Supply
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>: NA</Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                SAC Code
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>: NA</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                First Name
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.customerDetailsId.first_name}
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Last Name
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.customerDetailsId.last_name}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Address 1
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.customerDetailsId.address_line_1}
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Address 2
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.customerDetailsId.address_line_2}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>City</Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.customerDetailsId.city}
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                State
              </Text>
            </View>
            <View style={{ ...styles.cell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.customerDetailsId.state}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Mobile No:
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.customerDetailsId.mobile_number}{" "}
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                Email
              </Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text style={{ ...styles.text, ...styles.headerText }}>
                : {certificateDetails.customerDetailsId.email}
              </Text>
            </View>
          </View>
        </View>

        <Text style={{ ...styles.content }}>
          {" "}
          The services provided under the Assistance are as under:-
        </Text>

        <Text style={styles.title}> PA Policy Details</Text>
        <Text style={styles.title}>
          {" "}
          Toll Free No. – 24 X 7 Dedicated Toll Free No. for customers
        </Text>
        <Text style={styles.title}> Coverage Area – National Coverage</Text>
        <Text style={styles.title}> Doctor Teleconsultation</Text>
        <Text style={styles.content_2}>
          Customers can avail of Teleconsultation via Audio or Video mode
          whichever isrequired.
        </Text>

        <Text style={styles.title}> House Call Doctor Consultation</Text>
        <Text style={styles.content_2}>
          Customerscan avail of Doctor consultationsat their Homes
        </Text>

        <Text style={styles.title}>Ambulance Assistance </Text>
        <Text style={styles.content_2}>
          In event of any medical emergency which requirestransfer via Ground
          Ambulance, AcrossAssist will assist in making the arrangementsfrom the
          point of incidence. Thisservice will be on a referral basisand all
          paymentsshall be made directly by the Customer to the provider
        </Text>

        <Text style={styles.title}>Second Medical Opinion </Text>
        <Text style={styles.content_2}>
          If requested, AcrossAssist will assist the Customer in obtaining
          accessto legal/medical advice or treatment. AcrossAssist’steam will
          refer the Customer to the nearest appropriate medical Center for
          treatment. Consultation and treatment feesare the responsibility of
          the Customer. Thisservice will be on a referral basisand all
          paymentsshall be made directly by the Customer to the provider.
        </Text>

        <Text style={styles.title}>Impatient and outpatient Assistance </Text>
        <Text style={styles.content_2}>
          AcrossAssist can assist customersin arranging Inpatient
          Hospitalization orOutpatient hospital consultation visit. Chargesare
          to be borne by the customers
        </Text>

        <Text style={styles.title}>
          Repatriation of mortal remain Assistance{" "}
        </Text>
        <Text style={styles.content_2}>
          In event of any repatriation of Mortal Remainsrequirement,
          AcrossAssist will assist in making the arrangementsfrom the point of
          incidence. Thisservice will be on a referral basisand all
          paymentsshall be made directly by the consumer to the provider
        </Text>

        <Text style={styles.title}>Medical Evacuation </Text>
        <Text style={styles.content_2}>
          In event of any medical evacuation requirement, AcrossAssist will
          assist in making the arrangementsfrom the point of incidence.
          Thisservice will be on a referral basisand all payments shall be made
          directly by the Customer to the provider.
        </Text>

        <Text style={styles.title}>Cashless Hospitalisation (Assistance) </Text>
        <Text style={styles.content_2}>
          It is a facility wherein the insurance provider will settle your
          billsdirectly with the hospital. You do not have to pay a single penny
          asthe insurance company will cover the expenseson your behalf
        </Text>

        <Text style={styles.title}>Personal Accident Cover- 15 Lakhs </Text>
        <Text style={styles.content_2}>
          Sum Insured: Rs.15 lacs/- (Accidental Death). Cover isvalid only while
          the insured isdriving the vehicle covered under the Medical Assistance
          plan mentioned above. The coverage for CPA isfrom TATA AIGGeneral
          Insurance Master Policy Number 0239765313
        </Text>

        <View style={styles.addressContainer}>
          <View style={styles.address_div}>
            <Text style={styles.content}>Across Assist Private Limited </Text>
            <Text style={{ ...styles.content_2, marginBottom: "0" }}>
              CIN - U93000DL2017PTC324745
            </Text>

            <Text style={{ ...styles.content_2, marginBottom: "0" }}>
              Corporate Office
            </Text>
            <Text style={{ ...styles.content_2, marginBottom: "0" }}>
              3rd Floor, Plot No. 98,{" "}
            </Text>

            <Text style={{ ...styles.content_2, marginBottom: "0" }}>
              {" "}
              Gurugram,Sector 44 Haryana,122003
            </Text>

            <Text style={{ ...styles.content_2, marginBottom: "0" }}>
              Tel: +91 11 42221401,Fax: +91 11 43018631
            </Text>
            <Text style={{ ...styles.content_2, marginBottom: "0" }}>
              Website: www.accrossassist.com
            </Text>
          </View>

          <View style={{ ...styles.address_div }}>
            <Text
              style={{
                ...styles.content_2,
                marginBottom: "0",
                marginTop: "40",
              }}
            >
              Regd. Office
            </Text>

            <Text style={{ ...styles.content_2, marginBottom: "0" }}>
              AcrossAssist Crescent, 1, Nelson Mandela Road
            </Text>
            <Text style={{ ...styles.content_2, marginBottom: "0" }}>
              Vasant Kunj, Phase - II, NewDelhi,
            </Text>

            <Text style={{ ...styles.content_2, marginBottom: "0" }}>
              {" "}
              South Delhi,Delhi,India, 110070 India
            </Text>

            <Text style={{ ...styles.content_2, marginBottom: "0" }}>
              Tel: +91 11 46666100,Fax: +91 11 41686137
            </Text>
            <Text style={{ ...styles.content_2, marginBottom: "0" }}>
              Website: www.accrossassist.com
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Pdf;
