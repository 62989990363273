import { getTokenDetails } from '../../../utils/auth';
import AuthContext from '../../../store/auth-context';
import React,{useState , useEffect , useContext} from 'react'
import { useParams } from 'react-router-dom'


const CertificateId = () => {
    const context = useContext(AuthContext);
    let {id} = useParams();
    const [certificateDetails, setCertificateDetails] = useState('');

    useEffect(() => {
        getCertificateDetails(id)
    }, []);

    const getCertificateDetails = async (id) => {
        let tokenDetails = getTokenDetails()


        const response = await fetch("https://spa-insurance.forklyft.in/v1/dealer/get-certificate?id=" + id, {

            // Adding method type
            method: "GET",



            // Adding headers to the request
            headers: {
                'Authorization': 'Bearer ' + tokenDetails.token,
                "Content-type": "application/json"
            }
        }
        )
        const resp = await response.json();
        setCertificateDetails(resp.message)
        console.log(resp)


    }


  return (
      <div className=' w-[80%]'>
          <h1 className='text-left text-2xl font-semibold mb-4'>Certificate Details</h1>

          {certificateDetails !== '' ? (
            <>

                  <div className='flex bg-[transparent]  w-full justify-between'>

                      <div className='bg-[#fff] flex flex-col items-start  w-[32%]'>
                          <h2 className=' text-left pl-8 py-6 mb-8 text-xl font-[400] w-full border-b-2'>Customer Details</h2>
                          <div className='pl-8'>
                              <div className='flex flex-col items-start pb-8'>
                                  <h3 className='text-[#646c9a] font-bold'>Certificate Number</h3>
                                  <p>{certificateDetails.certificate_number}</p>
                              </div>

                              <div className='flex flex-col items-start pb-8'>
                                  <h3 className='text-[#646c9a] font-bold'>Full Name</h3>
                                  <p>{certificateDetails.customerDetailsId.first_name} {certificateDetails.customerDetailsId.last_name}</p>
                              </div>

                              <div className='flex flex-col items-start pb-8'>
                                  <h3 className='text-[#646c9a] font-bold'>Contact Details</h3>
                                  <p>{certificateDetails.customerDetailsId.email}</p>
                              </div>

                              <div className='flex flex-col items-start pb-8'>
                                  <h3 className='text-[#646c9a] font-bold'>Address</h3>
                                  <p>-{certificateDetails.customerDetailsId.address_line_1}<br /> -{certificateDetails.customerDetailsId.address_line_2}</p>
                              </div>
                          </div>
                      </div>

                      <div className='bg-[#fff] flex flex-col items-start  w-[32%]'>
                          <h2 className=' text-left pl-8 py-6 mb-8 text-xl font-[400] w-full border-b-2'>Vehicle Details</h2>
                          <div className='pl-8'>
                              <div className='flex flex-col items-start pb-8'>
                                  <h3 className='text-[#646c9a] font-bold'>Make and Model</h3>
                                  <p>{certificateDetails.vehicleDetailsId.make + " " + certificateDetails.vehicleDetailsId.model + " " + certificateDetails.vehicleDetailsId.variant}</p>
                                  <p>{certificateDetails.vehicleDetailsId.vehicle_status + " " + certificateDetails.vehicleDetailsId.vehicle_type}</p>
                              </div>

                              <div className='flex flex-col items-start pb-8'>
                                  <h3 className='text-[#646c9a] font-bold'>Registration Details</h3>
                                  <p>{certificateDetails.vehicleDetailsId.registration_number}</p>
                              </div>

                              <div className='flex flex-col items-start pb-8'>
                                  <h3 className='text-[#646c9a] font-bold'>Chasis No</h3>
                                  <p>{certificateDetails.vehicleDetailsId.chasis_number}</p>
                              </div>

                              <div className='flex flex-col items-start pb-8'>
                                  <h3 className='text-[#646c9a] font-bold'>Engine No</h3>
                                  <p>{certificateDetails.vehicleDetailsId.engine_number}</p>
                              </div>

                              <div className='flex flex-col items-start pb-8'>
                                  <h3 className='text-[#646c9a] font-bold'>Color</h3>
                                  <p>{certificateDetails.vehicleDetailsId.color}</p>
                              </div>
                          </div>
                      </div>

                      <div className='bg-[#fff] flex flex-col items-start  w-[32%]'>
                          <h2 className=' text-left pl-8 py-6 mb-8 text-xl font-[400] w-full border-b-2'>Plans Details</h2>
                          <div className='pl-8'>
                              <div className='flex flex-col items-start pb-8'>
                                  <h3 className='text-[#646c9a] font-bold'>Plan Name</h3>
                                  <p>{certificateDetails.planDetailsId.plan}</p>
                              </div>

                              <div className='flex flex-col items-start pb-8'>
                                  <h3 className='text-[#646c9a] font-bold'>Price</h3>
                                  <p>₹{certificateDetails.planDetailsId.plan_amount}</p>
                              </div>

                              <div className='flex flex-col items-start pb-8'>
                                  <h3 className='text-[#646c9a] font-bold'>Policy Start Date</h3>
                                  <p>{certificateDetails.planDetailsId.start_date}</p>
                              </div>

                              <div className='flex flex-col items-start pb-8'>
                                  <h3 className='text-[#646c9a] font-bold'>Policy End Date</h3>
                                  <p>{certificateDetails.planDetailsId.end_date}</p>
                              </div>
                          </div>
                      </div>

                  </div>


                 <div className='text-[#646c9a] py-8 text-left'>
              Certificate issued on {certificateDetails.planDetailsId.start_date} by {certificateDetails.customerDetailsId.email } <br/>
              2024 ©
              Powered by Across Assist
          </div>
            </>

              
            
          ):(
                  <svg className="animate-spin -ml-1 mr-3 h-[4%] my-[14vw]  w-full text-[var(--primary)] text-xl" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
          )}


          

         





      </div>
  )
}

export default CertificateId