import React, { useState, useEffect } from "react";
import { getTokenDetails } from "../utils/auth";
import { queryClient } from "../App";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getPlans, togglePlan } from "../utils/auth";

const TogglePlans = () => {
  const { data } = useQuery({ queryKey: ["plans"], queryFn: getPlans });
  const mutation = useMutation({
    mutationFn: togglePlan,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["plans"] });
    },
  });

  return (
    <>
      <h2 className="text-[#000] font-semibold text-2xl text-left my-[2%]">
        Deactivate Plans
      </h2>
      <div className=" w-full flex  justify-between flex-wrap gap-y-[1.5rem] bg-[#fff] rounded-sm px-8 py-8">
        <div className="flex items-center w-full">
          <ul className="certificate_table dealer_table flex w-full justify-start gap-[2%]  items-center py-4 border-b-2 border-[#f0f3ff]">
            {/* <li >{data.id}</li> */}
            <li className="font-semibold">Plan Name</li>
            <li className="font-semibold">Plan Value</li>

            <li className="font-semibold">Deactivate</li>
          </ul>
        </div>
        {data &&
          data.map((value, key) => (
            <div key={key} className="flex items-center w-full">
              <ul className="certificate_table dealer_table flex w-full justify-start gap-[2%]  items-center py-4 border-b-2 border-[#f0f3ff]">
                {/* <li >{data.id}</li> */}
                <li className="font-semibold">{value.name}</li>
                <li>{value.price}</li>

                <li>
                  <button
                    onClick={() => {
                      let { id } = value;
                      mutation.mutate({ id });
                    }}
                    className="px-8 py-3 mt-8 rounded-[5px] text-white bg-[var(--primary)] text-xl"
                  >
                    Deactivate
                  </button>
                </li>
              </ul>
            </div>
          ))}
      </div>

      <div></div>
    </>
  );
};

export default TogglePlans;
