import { getTokenDetails } from "../../utils/auth";
import AuthContext from "../../store/auth-context";
import React, { useState, useContext } from "react";

const Step1 = ({ data, setData }) => {
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const context = useContext(AuthContext);

  async function getVehicleBrands(vehicle, token) {
    //     fetch2Wheelers();
    //     return
    //     }
    const getBrands =
      "https://spa-insurance.forklyft.in/v1/dealer/get-vehicle-brands?type=" +
      vehicle;

    try {
      fetch(getBrands, {
        headers: {
          Authorization: "Bearer " + token,
        },
      }).then(async (response) => {
        let resp = await response.json();

        // console.log(resp.message, "#BRANDS");
        setBrandList(resp.message);
        setModelList([]);
      });
    } catch (error) {
      alert("Something went wrong.");
      return;
    }
  }

  async function getVehicleModels(vehicle, token) {
    const getModels = `https://spa-insurance.forklyft.in/v1/dealer/get-vehicle-models?type=${data.vehicle_type}&brand=${vehicle}`;

    try {
      fetch(getModels, {
        headers: {
          Authorization: "Bearer " + token,
        },
      }).then(async (response) => {
        let resp = await response.json();

        // console.log(resp, "#Models");
        setModelList(resp.message);
      });
    } catch (error) {
      alert("Something went wrong.");
      return;
    }
  }

  const handleChange = (e) => {
    let value = e.target.value;
    let ele = document.getElementsByName(e.target.name)[0].parentNode;
    let errorText = ele.querySelector(".error-text");
    if (value !== "" && errorText) {
      errorText && ele.removeChild(errorText);
    }
    if (e.target.name === "model") {
      setData({
        ...data,
        [e.target.name]: value,
        vehicle_id: e.target[e.target.selectedIndex].getAttribute("id"),
      });
    } else {
      setData({
        ...data,
        [e.target.name]: value,
      });
    }
    let { token } = getTokenDetails();

    if (e.target.name === "vehicle_type") {
      getVehicleBrands(value, token);
    }
    if (e.target.name === "make") {
      getVehicleModels(value, token);
    }
  };

  return (
    <div className="w-[60%] ">
      <h2 className="text-[#000] font-semibold text-2xl text-left">
        Vehicle Details
      </h2>
      <div className=" w-full flex mt-[5%] justify-between flex-wrap gap-y-[1.5rem]">
        <div className="form-group w-[45%] text-left">
          <label>Vehicle Type</label>
          <div className="dropdown bootstrap-select form-control kt_">
            <select
              className=""
              value={data.vehicle_type}
              name="vehicle_type"
              onChange={handleChange}
              id="input--vehicle-type"
              title="Select vehicle type"
              tabIndex="-98"
              aria-describedby="input--vehicle-type-error"
              aria-invalid="false"
            >
              <option value="" hidden>
                Select Vehicle Type
              </option>
              <option value="TwoWheeler">Two Wheeler</option>
              <option value="FourWheeler">Four Wheeler</option>
              <option value="CommercialVehicle">Commercial Vehicle</option>
            </select>
          </div>
        </div>

        <div className="form-group w-[45%] text-left">
          <label>Vehicle Status</label>
          <div className="dropdown bootstrap-select form-control kt_">
            <select
              className=""
              value={data.vehicle_status}
              name="vehicle_status"
              onChange={handleChange}
              id="input--vehicle-type"
              title="Select vehicle type"
              tabIndex="-98"
              aria-describedby="input--vehicle-type-error"
              aria-invalid="false"
            >
              <option value="" hidden>
                Select Vehicle Status
              </option>
              <option value="New">New</option>
              <option value="Old">Old</option>
            </select>
          </div>
        </div>

        <div className="form-group  text-left">
          <label>Make</label>
          <div className="dropdown bootstrap-select form-control kt_">
            <select
              className=""
              value={data.make}
              name="make"
              onChange={handleChange}
              id="input--vehicle-type"
              title="Select vehicle type"
              tabIndex="-98"
              aria-describedby="input--vehicle-type-error"
              aria-invalid="false"
            >
              <option value="" hidden>
                Select Vehicle Make
              </option>
              {brandList.length > 0 &&
                brandList.map((data, key) => (
                  <option key={key} value={data.vehicle_brand}>
                    {data.vehicle_brand}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="form-group  text-left">
          <label>Model</label>
          <div className="dropdown bootstrap-select form-control kt_">
            <select
              className=""
              value={data.model}
              name="model"
              onChange={handleChange}
              id="input--vehicle-type"
              title="Select vehicle type"
              tabIndex="-98"
              aria-describedby="input--vehicle-type-error"
              aria-invalid="false"
            >
              <option value="" hidden>
                Select a Model
              </option>
              {modelList.length > 0 &&
                modelList.map((data, key) => (
                  <option key={data.id} id={data.id} value={data.name}>
                    {data.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="form-group  text-left">
          <label>Variant</label>
          <div className="">
            <input
              placeholder="Enter Vehicle Variant"
              name="variant"
              onChange={handleChange}
              value={data.variant}
              type="text"
            />
          </div>
        </div>

        <div className="form-group  text-left">
          <label>Color</label>
          <div className="">
            <input
              placeholder="Enter Vehicle Color"
              name="color"
              onChange={handleChange}
              value={data.color}
              type="text"
            />
          </div>
        </div>

        <div className="form-group  text-left">
          <label>Engine Number</label>
          <div className="">
            <input
              placeholder="Enter Engine Number"
              name="engine_number"
              onChange={handleChange}
              value={data.engine_number}
              type="text"
            />
          </div>
        </div>

        <div className="form-group  text-left">
          <label>Chasis Number</label>
          <div className="">
            <input
              placeholder="Enter Chasis Number"
              name="chasis_number"
              onChange={handleChange}
              value={data.chasis_number}
              type="text"
            />
          </div>
        </div>

        <div className="form-group  text-left">
          <label>Registration Number</label>
          <div className="">
            <input
              placeholder="Enter Registration Number"
              name="registration_number"
              onChange={handleChange}
              value={data.registration_number}
              type="text"
            />
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default Step1;
