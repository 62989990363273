import React from 'react'

const ContactUs = () => {
  return (
      <div className=' w-[80%]'>
          <h1 className='text-center text-4xl mt-[5vw] font-semibold mb-4 text-[#646c9a] pb-10 border-b-2'>Our teams are here to help</h1>

          <div className='flex bg-[transparent]  w-full justify-start gap-4 pt-4 flex-wrap'>

              <div className='bg-[#fff] flex flex-col items-start justify-between  w-[32%] px-4 py-4 '>
                  <h3 className='text-[#646c9a] font-semibold'>Aman Kumar Singh</h3>
                  <p className='leading-7 sm:text-sm'>Email :<a className='text-[var(--primary)] font-[400]' href="mailto:aman.singh@acrossassist.com">aman.singh@acrossassist.com</a></p>
                  <p className='leading-7 sm:text-sm'>Phone : <a className='text-[var(--primary)] font-[400]' href="tel:9958708654">9958708654</a></p>
              </div>

              <div className='bg-[#fff] flex flex-col items-start justify-between  w-[32%] px-4 py-4 '>
                  <h3 className='text-[#646c9a] font-semibold'>Rohan Thareja</h3>
                  <p className='leading-7 sm:text-sm'>Email : <a className='text-[var(--primary)] font-[400]' href="mailto:rohan.thareja@acrossassist.com">rohan.thareja@acrossassist.com</a></p>
                  <p className='leading-7 sm:text-sm'>Phone : <a className='text-[var(--primary)] font-[400]' href="tel:9873736899">9873736899</a></p>
              </div>

              <div className='bg-[#fff] flex flex-col items-start justify-between  w-[32%] px-4 py-4 '>
                  <h3 className='text-[#646c9a] font-semibold'>Aman Kumar Singh</h3>
                  <p className='leading-7 sm:text-sm'>Email : <a className='text-[var(--primary)] font-[400]' href="mailto:atul.gharwar@acrossassist.com">atul.gharwar@acrossassist.com</a></p>
                  <p className='leading-7 sm:text-sm'>Phone :<a className='text-[var(--primary)] font-[400]' href="tel: 9891093392"> 9891093392</a></p>
              </div>

              <div className='bg-[#fff] flex flex-col items-start justify-between  w-[32%] px-4 py-4 '>
                  <h3 className='text-[#646c9a] font-semibold'>Patrick Culas</h3>
                 
                  <p className='leading-7 sm:text-sm'>Phone :<a className='text-[var(--primary)] font-[400]' href="tel: 9891093392"> 7094914422</a></p>
              </div>

              <div className='bg-[#fff] flex flex-col items-start justify-between  w-[32%] px-4 py-4 '>
                  <h3 className='text-[#646c9a] font-semibold'>Raju Kumar</h3>

                  <p className='leading-7 sm:text-sm'>Phone :<a className='text-[var(--primary)] font-[400]' href="tel: 9891093392"> 9910466391</a></p>
              </div>

           

          </div>



          <div className='text-[#646c9a] py-8 text-left'>

              2024 ©
              Powered by Across Assist
          </div>





      </div>
  )
}

export default ContactUs