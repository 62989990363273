import Footer from '../components/footer'
import Header from '../components/header'
import React from 'react'

const Terms = () => {
    return (
        <>
        <Header/>

        <div className='bg-[#fff] '>
            <div className='border-2 w-[65%] mx-auto text-left terms' >

                <h1 className='text-[#1363df] uppercase text-4xl '>Terms & Conditions</h1>
                <div>
                    <h3>Acknowledgement and acceptance of these terms of services</h3>
                    <p>This page states the Terms and Conditions under which you may use this Website. Please read this page carefully before accessing or using the services.</p>
                    <p>Across Assist Private Limited (“Company” or “We” or “Us”) is the owner of this Website and reserves the right to change these Terms and Conditions at any time without notice to you, and upon such change, the same shall be effective immediately. You agree to review these Terms and Conditions on a regular basis. By using the Mobile app called Across Assist Secure and / or Website, you are agreeing to be bound by these Terms and Conditions.</p>
                    <p>These Terms and Conditions, together with the privacy policy, and other rules, if any, posted on the Across Assist Secure app and / or Website, constitute the entire agreement between you and the Company with respect to your use of the Across Assist Secure App / Website, and they supersede all prior communications, proposals, and terms and conditions between you and the Company with respect to the App / Website. Any rights not expressly granted in these Terms and Conditions are reserved by the Company.</p>

                </div>

                <div>
                    <h3>Definitions</h3>
                    <p>In this document, the following words and phrases have the meanings set forth herein unless the context indicates otherwise:</p>
                    <p>“Agreement” means the terms and conditions as detailed herein, including the privacy policy. It shall include the references to these terms and conditions as amended, negated, supplemented, varied or replaced from time to time.</p>
                    <p>“Across Assist” or “BA” or “Company” or “We” or “Us” shall mean Across Assist Private Limited.</p>

                    <p>“Call Center” means the call center facility set up by Across Assist to assist its Customers with respect to various transactions and to furnish information etc., regarding its Plans and such other related information. The Customers can presently reach BA on the numbers notified on the app and / or Website.</p>
                    <p>“Customer” means a purchaser of BA’s Accidental Damage / Liquid Damage Plan and Extended Warranty Plan (“ADLD and EW Plan”) from BA.</p>
                    <p>“Record/s” means any electronic records that are submitted through the Across Assist Secure app and/or Website and includes physical records collected by an Authorized agent of BA from the customer or User for effecting the transaction.</p>
                    <p>“Personally identifiable information” shall mean such information submitted by the Customer or User that enables individual identification of the User either by itself or in association with other material.</p>
                    <p>“You” means any person who accesses or visits the Across Assist Secure app and/or Website</p>
                    <p>“Services” means and constitute a technology platform that enables users of BA’s mobile applications or websites provided as part of the Services to arrange and schedule purchase, repair, sell and/or logistics services through independent third party providers of such services</p>

                </div>

                <div>
                    <h3>Applicable Law</h3>
                    <p>This site is created and controlled by BA. The laws of India shall apply and courts in Delhi shall have jurisdiction in respect of all the terms, conditions and disclaimers herein.</p>
                    <p>The Company accepts no liability whatsoever, direct or indirect for non-compliance with the laws of any country other than that of India. The mere fact that the Across Assist Secure app and/or Website can be accessed in a country other than India does not mean that the laws of the said country would govern these Terms and/or the Website operations of the User and/or the use of the app or the Website by a visitor.</p>
                   

                </div>

                <div>
                    <h3>Eligibility</h3>
                    <p>You represent and warrant that you are competent and eligible to enter into legally binding agreement and have the requisite authority to enter into this Agreement and perform your obligations herein. You shall not use the Across Assist Secure App and / or the Website if you are not competent to contract under the applicable laws, rules and regulations.</p>
                  


                </div>

                <div>
                    <h3>Registration Requirement</h3>
                    <p>In the event of registration of the User on the Across Assist Secure App and / or Website, the User shall at all times be responsible for maintaining the confidentiality of his/her password and user id, and shall be fully responsible for all activities that occur by use of such password or user id. Further, the User agrees not to use any other person’s user ID and/or password for any purpose whatsoever.</p>
                    <p>User is responsible for the security of User’s password and for all transactions undertaken through the Across Assist Secure App and / or Website using the User’s password. User confirms that he/she is the authorized holder of the credit card or the original account used for the transactions under the Across Assist Secure App and / or Website. The Company shall not be responsible for any financial loss or inconvenience resulting from misuse of user’s ID/password/credit card number/account details for the purchase of Plan / or during registration of the claims on the Across Assist Secure App and / or Website.</p>

                    <p>The User also agrees and undertakes to immediately notify BA of any unauthorized use of the User’s password or user ID. The User shall ensure that the User logs off from the Website at the end of each session at the Website. BA shall not be responsible for any direct or indirect loss or damage arising out of the User’s failure to comply with this requirement.</p>
                    <p>As part of the registration process, the User does hereby agree to provide BA his/her current, complete, and accurate information as prompted and to maintain and update this information as and when required keeping it current, complete and accurate at all times.</p>
                    <p>If you provide any information that is untrue, inaccurate not current or incomplete or if the Company has reasonable grounds to suspect that such information is untrue, inaccurate, not current, incomplete or mischievous, BA has the right to indefinitely suspend or terminate your membership and can block the User from using the Across Assist Secure App and / or Website.</p>


                </div>

                <div>
                    <h3>Terms of Service</h3>
                    <p>This Agreement shall continue to be valid and in effect as long as you are using the app and / or Website or the services provide through these mediums.</p>
                    <p>You agree to use such services of the Company in a manner consistent with the terms and conditions of this Agreement and with all applicable laws and regulations in India from time to time.</p>

                    <p>Further, the User of this Website does hereby agree and understands that the information furnished on this Website is for information and guidance purpose only and must not be construed as a recommendation by BA to purchase any Plan / raise any request for claim settlement. The decision to purchase the Plan should be based on your own evaluation of the product. Further, you must exercise due care in ensuring the completeness and accuracy of the information provided. BA and its employees, affiliates shall not be liable for any loss or damage caused by or arising out of reliance placed on such incomplete or inaccurate information.</p>
                    <p>Further, by effecting a transaction that may be allowed by BA on the Website, you agree to provide complete and irrevocable authority to BA to take steps and carry out tasks that are essential and relevant to provision of the services for which the transaction is effected. You further acknowledge and accept that the records maintained by BA with respect to the transactions effected by you / claims request raised by you on the app / Website shall be the conclusive evidence of such transaction and shall be final and binding on you and your assigns and successors.</p>
                    <p>BA reserves the right to suspend, withdraw, modify, add or cancel any or all of the services offered on the app and/or Website without any prior notice. Any such suspension, withdrawal, modification, addition or cancellation shall become binding on You forthwith.</p>


                </div>

                <div>
                    <h3>Third Party Services and Content</h3>
                    <p>The Services may be made available or accessed in connection with third party services, products, warranty and content (including advertising) that BA may / may not control directly. You acknowledge that different terms of use, warranty and privacy policies may apply to your use of such third party services, product and content. In no event shall BA be responsible or liable for any products, warranty or services of such third party providers.</p>
              


                </div>

                <div>
                    <h3>Pickup and Drop Service for Devices During Repair.</h3>
                    <p>BA may, at its sole discretion, offer pickup and drop-off (PUDO) services for repair at select locations and for select brands and devices. These services may be charged or complimentary depending on the benefits available to the customer. BA may avail the services of third party service providers for the fulfilment of PUDO services. BA and its associates or third party vendors or contractors or sub-contractors shall undertake reasonable care to ensure the safety of the devices during transit. BA may, at its discretion, also provide reasonable and commercially viable compensation in case of loss to the device during transit. The customer agrees to such compensation as determined by BA as final and binding compensation for the device lost in transit or while in the custody of BA for repair.</p>



                </div>

                <div>
                    <h3>Text Messaging and Notifications</h3>
                    <p>By creating an Account, you agree that the Services may send you informational text (SMS) messages and notifications as part of the normal business operation of your use of the Services. You may opt-out of receiving text (SMS) messages from BA at any time by sending an email to contact@acrossassist.com indicating that you no longer wish to receive such messages, along with the phone number of the mobile device receiving the messages. You acknowledge that opting out of receiving text (SMS) messages may impact your use of the Services.</p>



                </div>

                <div>
                    <h3>User Account Data</h3>
                    <p>The Company respects the privacy of every individual. We protect the personal information in accordance with our privacy policy set out below and respect the confidentiality of the user.</p>

                    <p>The information is kept confidential and is shared only in the manner detailed in the Privacy Policy below. However, You shall also be responsible for maintaining the confidentiality and security of your account and password and you agree to accept the responsibility for all the activities that occur using your account and password. The Company shall not be responsible for any loss or damage due to your failure to safeguard your account or password.</p>
                    <p>You shall notify the Company by writing to a contact@acrossassist.com. of any suspected unauthorized use(s) of your account or password, or any breach of security, including loss, theft, or unauthorized disclosure of your account password as soon as the same comes to your notice.</p>



                </div>

                <div>
                    <h3>Privacy Policy</h3>
                    <p>For our policy on Privacy, kindly refer to our Privacy Policy section appearing on the main page of our website as well as on the Across Assist Secure app.</p>

                </div>


                <div>
                    <h3>Information on the Across Assist Secure App / Website</h3>
                    <p>Whilst every effort is made to update the information contained on the Across Assist Secure app and / or Website, neither BA nor any third party or data or content provider makes any representations or warranties, whether express, implied in law or residual, as to the sequence, accuracy, completeness or reliability of information, opinions, research information, data and/or content contained on the app and/or Website (including but not limited to any information which may be provided by any third party or data or content providers) (“information”) and shall not be bound in any manner by any information contained on the Across Assist Secure app and / or Website.</p>

                    <p>The Company reserves the right at any time to change or discontinue without notice, any aspect or feature of the app / Website. No information provided on the App / Website shall be construed as an advice or recommendation.</p>
                    <p>You agree that the information on the App / Website with respect to any Plan that you may purchase is merely indicative of the terms of such Plan. In the event, with respect to a Plan, there is any conflict between the terms & conditions of the Plan and the terms herein, you shall be governed by the terms & conditions of the Plan.</p>

                </div>

                <div>
                    <h3>Intellectual Property</h3>
                    <p>The trade marks, names, logos and service marks (collectively “trademarks”) displayed on the App / Website are registered and/or unregistered trademarks of the Company. Nothing contained on the App / Website should be construed as granting any license or right to use any of the trademarks without the prior written permission of the Company.</p>

                    <p>Copyright and other intellectual property rights with respect to the contents on this Website are solely and exclusively owned by the Company. All rights in the pages, site contents are owned by the Company and the User is prohibited from copying, modifying, displaying, distributing, transmitting, re-delivering, publishing, selling, licensing, creating derivative works or using any content of the App / Website for any purpose without the prior written consent of the Company.</p>
                    <p>Everything you see or read on the App / Website is copyrighted/ protected by intellectual property laws.</p>

                </div>

                <div>
                    <h3>Electronic Communication</h3>
                    <p>The users of the App / Website do hereby agree to receive communication from the Company and the App / Website electronically either by e-mails or through announcements on the App / Website.</p>

              

                </div>

                <div>
                    <h3>Card or Bank Account Details</h3>
                    <p>You agree, understand and confirm that the bank or credit / debit card details provided by you for buying the Plans offered by BA on its mobile app / Website shall be correct and accurate and you shall not use the bank account and / or credit / debit card which is not lawfully owned by you. You further agree and undertake to provide the correct and valid bank account and / or credit / debit card details to BA.</p>

                    <p>Further, the said information shall be not be stored, utilized and shared with any of the third parties by the Company unless required by law, regulation or court order. You shall fully indemnify the Company from any loss or damage arising out of a non-compliance of this condition by you.</p>
                  

                </div>

                <div>
                    <h3>Cancellation Policy</h3>
                    <p>The User does hereby agree that before purchasing the Plan / or raising a request for claim available on this Website or App, the User shall make himself/herself fully aware of its features and benefits offered there under. Further, the User does hereby agrees that cancellation of a plan purchased by the User online on the App or through the Website shall be subject to the terms and conditions of the respective Plan and notwithstanding anything contained elsewhere, the terms and conditions specified on the Plan shall always prevail</p>

                 

                </div>

                <div>
                    <h3>Links to Third Party Websites</h3>
                    <p>This App / Website may contain external links to websites operated by third parties other than BA. Such links are provided for your convenience only and BA has no control over such websites and no representation is being made by the Company as to its content and conduct. Use or reliance on any such links and the content thereon provided shall be at your sole risk.</p>

                    <p>When visiting such external links, you shall refer to the terms and conditions of use of such websites. No hypertext links shall be created from any website controlled by You or any other person, to this Website, without the express prior written permission of the Company.</p>



                </div>

                <div>
                    <h3>Downloads and Available Software</h3>
                    <p>Your usage of such software is governed by the terms of the end user license agreement, if any, which accompanies or is included in such software. You shall not install or use any software that is accompanied by or includes a License Agreement, unless you first agree to the License Agreement terms.</p>

                </div>

                <div>
                    <h3>Restrictions on Use</h3>
                    <h3>You shall not:</h3>
                    <p>a) Reverse engineer, decompile, disassemble, copy, reproduce, distribute, modify, transmit, perform, reproduce, publish or create derivative works from or in any way exploit any of the services or content on this App / Website in violation of the Terms and Conditions or the laws of any country.</p>

                    <p>b) Create a database in electronic or structured manual form by systematically downloading and storing all or any part of the App / Website save and except such information as relates to the Plan purchased by you.</p>
                    <p>c) Cache or permit caching by any person. Do any act that violates our intellectual property in the App / Website.</p>
                    <p>d) Do any act that violates our intellectual property in the App / Website.</p>
                    <p>e) Engage in any fraudulent, abusive or illegal activity, including but not limited to any communication or solicitation designed or intended to fraudulently obtain the password or any private information of any use.</p>
                    <p>f) Use the App / Website to violate the security of any computer network, crack pass words or security encryption codes, transfer or store illegal material including threatening or obscene material or engage in any kind of illegal activity.</p>

                </div>

                <div>
                    <h3>Other Terms</h3>
                  
                    <p>The App / Website is provided without any warranties or guarantees and on an “As Is” condition. You must bear the risks associated with the use of the App / Website. While BA tries to ensure that material included on the App / Website is correct, it cannot be held liable for any inaccuracy of the same. BA shall not be responsible for any errors or omissions or for the results obtained from the use of such information or for any technical problems you may experience while using the App / Website.</p>

                    <p>To the fullest extent permitted under applicable law, BA shall not be liable for any indirect, incidental, special, incidental, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising out of or in connection with the App / Website, its services or this Agreement.</p>
                    <p>BA makes no representations or warranties about the accuracy, reliability, completeness, correctness and/or timeliness of any content, information, software, text, graphics, link, statics or communications provided on or through the use of the Website or that the operation of the App / Website shall be error free and/or uninterrupted. Consequently, BA assumes no liability whatsoever for any monetary or other damage suffered by you on account of the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the App / Website and/or any interruption or errors in the operation of the App / Website.</p>
                    <p>Further in no event shall BA be liable for any direct, indirect, punitive, incidental, special or consequential damages or for any damages whatsoever including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of BA sites/services for interrupted communications, delay, lost data or lost profits arising out of or in connection with this agreement.</p>
                    <p>Even if BA has been advised of the possibility of damages, or inability to use the BA sites/services or related services, the provision of or failure to provide services, or for any information, software obtained through the BA sites/services, or otherwise arising out of the use of the BA sites/services, whether based on contract, tort, negligence, strict liability or otherwise, even if BA has been advised of the possibility of damages. BA does not endorse in anyway any advertisers/ contents of advertisers on the App / website.</p>
                   

                </div>

                <div>
                    <h3>Indemnity</h3>

                    <p>User agrees to defend, indemnify and hold harmless BA, its employees, directors, officers, partners and their successors and assigns from and against any and all claims, liabilities, damages, losses, costs and expenses, including attorney’s fees, caused by or arising out of claims based upon:</p>

                    <p>a) User’s actions or inactions, including but not limited to any warranties, representations or undertakings or in relation to the non-fulfillment of any of its obligations under this Agreement or arising out of the User’s infringement of any applicable laws, regulations including but not limited to intellectual property rights of the Company;</p>
                    <p>b) payment of statutory dues and taxes;</p>
                    <p>c) claim of libel, defamation, violation of rights of privacy or publicity, loss of service by other subscribers;</p>
                    <p>d) your use or someone else’s use of your access to the App / Website;</p>
                    <p>e) a claim that any use of the App / Website by you or someone using your computer infringes any intellectual property right of any third party, or any right of personality or publicity, is libelous or defamatory, or otherwise results in injury or damage to any third party; and/or</p>
                    <p>f) infringement of intellectual property rights or other rights of the Company.</p>


                </div>

                <div>
                    <h3>Updates</h3>

                    <p>We reserve the right to change or update the Agreement at any time by placing a prominent notice on our site. Such changes shall be effective immediately upon posting on the App / Website and you shall keep yourself updated of such changes.</p>

                </div>

                <div>
                    <h3>Severability</h3>

                    <p>If any part of this Agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision shall be deemed to be superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the Agreement shall continue in effect.</p>

                </div>

                <div>
                    <h3>Entire Agreement</h3>

                    <p>Unless otherwise specified herein, this Agreement constitutes the entire agreement between you and the Company with respect to the Company’s sites/services and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and the Company with respect to the the Company’s sites/services. The Company’s failure to act with respect to a breach by you or others does not waive its right to act with respect to subsequent or similar breaches.</p>

                </div>

                <div>
                    <h3>Assistance</h3>

                    <p>For any concerns or questions about any aspect of these Terms & Conditions of use of the App / Website, please feel free to contact at contact@acrossassist.com</p>

                </div>



                

              
            </div>

            
        </div>

        <Footer/>
        </>
    )
}

export default Terms