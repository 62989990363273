import React, { useState } from "react";

import TogglePlans from "../components/togglePlans";
import { useMutation } from "@tanstack/react-query";
import { createPlan } from "../utils/auth";
import { queryClient } from "../App";
const CreatePlan = () => {
  const mutation = useMutation({
    mutationFn: createPlan,
    onSuccess: (resp) => {
      // Invalidate and refetch
      setData({
        ...data,
        name: "",
        price: "",
        tax_slab: "",
      });
      alert(resp.message);
      setRefresh(!refresh);
      queryClient.invalidateQueries({ queryKey: ["plans"] });
    },
  });

  const [refresh, setRefresh] = useState(false);

  const [data, setData] = useState({
    name: "",
    price: "",
    tax_slab: "",
  });

  const handleChange = (e) => {
    let value = e.target.value;
    let ele = document.getElementsByName(e.target.name)[0].parentNode;
    let errorText = ele.querySelector(".error-text");
    if (value !== "" && errorText) {
      errorText && ele.removeChild(errorText);
    }
    setData({
      ...data,
      [e.target.name]: e.target.name === "name" ? value : Number(value),
    });
  };

  return (
    <div className="w-[52%] mx-4">
      <h2 className="text-[#000] font-semibold text-2xl text-left">
        Create Plan
      </h2>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          mutation.mutate({ data });
        }}
        className=" w-full flex mt-[2%] justify-between flex-wrap gap-y-[1.5rem] bg-[#fff] rounded-sm px-8 py-8"
      >
        <div className="form-group  text-left">
          <label>Plan Name</label>
          <div className="">
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={data.name}
              required
            />
          </div>
        </div>

        <div className="form-group  text-left">
          <label>Plan Price</label>
          <div className="">
            <input
              type="text"
              name="price"
              onChange={handleChange}
              value={data.price}
              required
            />
          </div>
        </div>

        <div className="form-group w-[45%] text-left">
          <label>GST (%)</label>
          <div className="custom-input">
            <input
              type="text"
              name="tax_slab"
              onChange={handleChange}
              value={data.tax_slab}
              required
            />
          </div>
        </div>

        <div className="form-group w-[45%] text-left">
          <label>Total (plan price + GST(18%))</label>
          <div className="custom-input">
            <input
              type="text"
              name="tax_slab"
              value={
                data.planPrice !== ""
                  ? Number(data.price) +
                    (Number(data.price) * Number(data.tax_slab)) / 100
                  : ""
              }
              readOnly
            />
          </div>
        </div>

        <button
          type="submit"
          className="px-8 py-3 mt-8 rounded-[5px] text-white bg-[var(--primary)] text-xl"
        >
          Submit
        </button>
      </form>

      <TogglePlans setRefresh={setRefresh} refresh={refresh} />
    </div>
  );
};

export default CreatePlan;
