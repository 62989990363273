import React from 'react'
import logo from '../images/logo_iag_CorePartner_transparent.png'
import payment_opt from '../images/payment_options.webp'
import footer_img from '../images/aa_logo_horizontal_small.webp'
import payment_secure from '../images/payment_secure.webp'
const Footer = () => {
  return (
    <div>

          <div   className="w-[87%] mx-auto my-8">
              <div className=" footer_bg  p-6 text-[#fff] flex justify-between items-center rounded-[5px]">
                  <div   className="col-md-8 d-flex align-items-center">
                      <h4   className="text-2xl my-8">Have any questions? <span   className="">Ask us anything, we'd love to answer!</span></h4>
                  </div>
                  <div   className="self-start">
                      <h2 className='flex flex-col items-end' >
                          <a   href="tel:+911204501400" className="text-3xl min-w-full ">
                              <i   aria-hidden="true" className="fa fa-phone-alt"></i> +91-120-450-1400
                          </a>
                          
                              <span  className='text-2xl opacity-50'>24×7</span>
                          
                      </h2>
                  </div>
              </div>
          </div>

          <footer className="bg-[#F0F4F8]">
              <div    className="mx-auto w-[85%] py-10">
                  <div    className="flex justify-between">
                      <div    className="w-[20%]">
                          <h4    className="text-3xl text-[var(--primary)]">Our Company</h4>
                          <ul    className="list-unstyled">
                              <li    className="footer-link my-1">
                                  <a    href="/">
                                      <span   >Home</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/about">
                                      <span    className="elementor-icon-list-icon">
                                      </span>
                                      <span   >About Us</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/team">
                                      <span    className="elementor-icon-list-icon">
                                      </span>
                                      <span   >Team</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/contact">
                                      <span    className="elementor-icon-list-icon">
                                      </span>
                                      <span   >Contact Us</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/privacy-policy">
                                      <span    className="elementor-icon-list-icon">
                                      </span>
                                      <span   >Privacy Policy</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/terms-conditions">
                                      <span   >Terms &amp; Conditions</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/terms-conditions-mobile">
                                      <span   >Terms (Across Assist Secure)</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/terms-conditions-tele-medicine">
                                      <span   >Terms (Tele-Medicine)</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/faqs">
                                      <span   >FAQs</span>
                                  </a>
                              </li>
                          </ul>
                      </div>
                      <div    className="w-[20%]">
                          <h4 className="text-3xl text-[var(--primary)]">Solutions</h4>
                          <ul    className="list-unstyled">
                              <li    className="footer-link my-1">
                                  <a    href="/service/medical-assistance">
                                      <span   >Medical Assistance</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/service/travel-assistance/">
                                      <span   >Travel Assistance</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/service/roadside-assistance/">
                                      <span   >Roadside Assistance</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/service/mobile-protection">
                                      <span   >Mobile Protection</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/service/warranty-assistance">
                                      <span   >Warranty Assistance</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/service/wallet-protection">
                                      <span   >Wallet Protection</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/service/corporate-mobility">
                                      <span   >Corporate Mobility</span>
                                  </a>
                              </li>
                              <li    className="footer-link my-1">
                                  <a    href="/service/corporate-wellness">
                                      <span   >Corporate Wellness</span>
                                  </a>
                              </li>
                          </ul>
                      </div>
                      <div    className="w-[20%]">
                          <h4 className="text-3xl text-[var(--primary)]">Get in touch</h4>
                          <p   >We’re here to listen:</p>
                          <a    href="tel:+911142221401" className="min-w-full  block text-[var(--primary)] text-sm my-3">
                              <i    className="fas fa-phone-alt opacity-50 "></i>
                              <strong   >+91-120-450-1401</strong>
                              <span    className="opacity-75">(Direct)</span>
                          </a>
                          <a    href="tel:+911204501400" className=" block text-[var(--primary)] text-sm my-3">
                              <i    className="fas fa-phone-alt opacity-50 "></i>
                              <strong   >+91-120-450-1400</strong>
                              <span    className="opacity-75">(Board Line)</span>
                          </a>
                          <a    href="email:contact@acrossassist.com" className=" block text-[var(--primary)] text-sm my-3">
                              <i    className="fas fa-mail-bulk opacity-50  "></i>
                              <strong   >contact@acrossassist.com</strong>
                          </a>
                      </div>
                      <div    className="w-[20%]">
                          <img    src={logo} alt='logo' className="" />
                              <p    className="m0 mt-4 mb-2 text-black-50">Across Assist is the core partner of IAG – International Assistance Group.</p>
                              <a    className="w-full text-[var(--primary)] font-semibold" href="https://www.international-assistance-group.com/bharti-assist-global-core-partner-appointed-for-india/">Read Announcement <i    className="fa fa-angle-right"></i></a>
                      </div>
                  </div>
              </div>
          </footer>


          <div   className="mx-auto w-full flex justify-center py-6 bg-[#fff]">
              <div   className="flex justify-between w-[85%] items-center">
                  <div   className="flex flex-wrap w-[30%]">
                      <p   className="w-full">
                          <small  >
                              <strong  >Secured Payment Gateways</strong>
                          </small>
                      </p>
                      <img   src={payment_opt} alt='payment options' />
                          <img   src={payment_secure} alt='payment_secure' />
                          </div>
                          <div   className="flex w-[30%]">
                              <img   src={footer_img} alt="Across Assist" className="img-fluid" />
                          </div>
                          <div   className="flex justify-end w-[30%] text-[var(--primary)]">
                              <a   href="https://www.facebook.com/acrossassist" className="m-1" >
                                  <i   className="fa fa-facebook-square fa-2x"></i>
                              </a>
                              <a   href="https://www.twitter.com/acrossassist" className="m-1" >
                                  <i   className="fa fa-twitter-square fa-2x"></i>
                              </a>
                              <a   href="https://www.linkedin.com/company/acrossassist" className="m-1" >
                                  <i   className="fa fa-linkedin fa-2x"></i>
                              </a>
                              <a   href="mailto:contact@acrossassist.com?subject=Inquiry%20from%20Website" className="m-1" >
                                  <i   className="fa fa-envelope-square fa-2x"></i>
                              </a>
                          </div>
                  </div>
              </div>

    </div>
  )
}

export default Footer