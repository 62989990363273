import { getTokenDetails } from '../utils/auth';
import AuthContext from '../store/auth-context';
import React , {useEffect , useState , useContext} from 'react';
import { useNavigate } from 'react-router-dom';
const AccountOverview = () => {
  const [dealerDetails, setDealerDetails] = useState('');
  const context = useContext(AuthContext);
  const navigate  = useNavigate()

  useEffect(() => {
    getDealerOverview();
  
  }, []);


  const getDealerOverview = async () => {
    try {
      let token = getTokenDetails()

      const response = await fetch("https://spa-insurance.forklyft.in/v1/dealer/get-dealer-overview", {

        // Adding method type
        method: "GET",



        // Adding headers to the request
        headers: {
          'Authorization': 'Bearer ' + token.token,
          "Content-type": "application/json"
        }
      }
      )
      const resp = await response.json();
      if(resp.status === 200){
        setDealerDetails(resp.message)
        // console.log(resp)

      }
     

      
    } catch (error) {
      console.log(error)
      
    }


  

  }
 

  
const generateOrder = async () => {
  let tokenDetails = getTokenDetails();
  try {
    const result = await fetch(
      "https://spa-insurance.forklyft.in/v1/dealer/create-phone-pay-order",
      {
        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: JSON.stringify({
          certificates:  ["all"] ,
          phoneNumber: "",
        }),

        // Adding headers to the request
        headers: {
          Authorization: "Bearer " + tokenDetails.token,
          "Content-Type": "application/json",
        },
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }
    const resp = await result.json();
    console.log(resp, "resp23");
     navigate("/payment/paynow", { state: { list: resp.message } });
    return resp.message;
  } catch (error) {}
};


  return (
    <div className='w-[80%]'>
      <h1 className='text-left text-2xl font-semibold mb-4'>Overview</h1>


       {dealerDetails === '' ? (
        
        <svg className="animate-spin -ml-1 mr-3 h-[4%] my-[14vw]  w-full text-[var(--primary)] text-xl" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
       ) :
       (

          <div className='flex bg-[transparent] w-full justify-between flex-col gap-4'>
            <div className='bg-[#fff] flex flex-col items-start justify-between w-[59%] pb-8'>
              <h2 className='text-left pl-8 py-6 mb-8 text-xl font-[400] w-full border-b-2'>Account Settings </h2>

              <div className='flex justify-between w-[95%] mx-auto'>
                <div className='text-left text-[#646c9a] font-bold'>
                  <p className='mb-1'>Commission Payout Rate</p>
                  <p className='mb-1'>Total Credit Limit</p>
                  <p className='mb-1'>Available Credit Limit</p>
                  <p className='mb-1'>Wallet</p>
                </div>

                <div className='text-right'>
                  <p>{dealerDetails.commission_payout_rate}%</p>
                  <p>₹{dealerDetails.total_credit_limit}</p>
                  <p>₹{dealerDetails.available_credit_limit}</p>
                  <p>₹{dealerDetails.wallet}</p>
                </div>
              </div>
            </div>

            <div className='bg-[#fff] flex flex-col items-start justify-between w-[59%] pb-8'>
              <h2 className='text-left pl-8 py-6 mb-8 text-xl font-[400] w-full border-b-2'>Account Statement</h2>
              <h3 className='w-[95%] text-left mx-auto text-[#646c9a] mb-2'>Certificate premium</h3>

              <div className='flex justify-between w-[95%] mx-auto border-b-2'>
                <div className='text-left text-[#646c9a] font-bold'>
                  <p className=' mb-1'>Certificates issued till date</p>
                  <p className=' mb-1'>Total Premium</p>
                  <p className=' mb-1'>Total Premium Paid</p>
                  <p className='text-[var(--primary)] font-bold text-left mb-1'>Net Premium Due</p>
                </div>

                <div>
                  <p>{dealerDetails.certificates_issued_till_date}</p>
                  <p>{dealerDetails.total_premium}</p>
                  <p>{dealerDetails.total_premium_paid}</p>
                  <p>{dealerDetails.net_premium}</p>

                  <button onClick={generateOrder} className='border-2 px-2 py-1 rounded-[10px] border-[var(--secondary)] my-4'>
                    Pay now
                  </button>
                </div>
              </div>

              <h3 className='w-[95%] text-left mx-auto text-[#646c9a] my-2 mt-4'>Commission</h3>
              <div className='flex justify-between w-[95%] mx-auto'>
                <div className='text-left text-[#646c9a] font-bold'>
                  <p className=' mb-1'>Total Commission on certificates</p>
                  <p className=' mb-1'>Commission received till date</p>
                  <p className=' mb-1'>Outstanding Commission</p>
                </div>

                <div>
                  <p>{dealerDetails.total_commission}</p>
                  <p>{dealerDetails.commission_received}</p>
                  <p>{dealerDetails.outstanding_commission}</p>

                </div>
              </div>
            </div>
          </div>

       )}
      

      <div className='text-[#646c9a] py-8 text-left'>
        2024 © Powered by Across Assist
      </div>
    </div>
  );
};

export default AccountOverview;
