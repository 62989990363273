import Footer from '../components/footer'
import  Header  from '../components/header'
import React from 'react'

const PrivacyPolicy = () => {
    return (
        <>
        <Header/>

        <div className=' '>
            <div className='border-2 w-[65%] mx-auto text-left terms' >

                <h1 className='text-[#1363df] uppercase text-4xl '>Privacy Policy</h1>
                <p>
                    We value you as a customer and respect your right to privacy. We believe You should always know what data We collect from You and how We use it, and that You should have meaningful control over both. We want to empower You to make the best decisions about the information that You share with Us. In the process of operating the mobile application or operating the website, we may become aware of information relating to you, including information that is of a confidential nature. We are strongly committed to protecting your privacy online and have taken steps to protect such information.<br /><br />

                    Please read on for more details about our privacy policy.
                </p>
                <div>
                    <h3>DEFINITIONS</h3>
                    <p>(a) “Across Assist” or “Us” or “We” means Across Assist Private Limited.</p>
                    <p>(b) “Call Center” means the call center facility set up/ engaged by Across Assist to assist its Customers with respect to various transactions and to furnish information etc., regarding its products and such other related information.</p>
                    <p>(c) “Personal Data” or “Personal Information” means any information relating to a Person who can be identified either:</p>
                    <p>– directly from that data; or</p>
                    <p>– from that data together with any other information which either is or likely to be available.”</p>
                    <p>Examples of Personal data include: name, pseudonyms, address, telephone number, identity card number, occupation, salary/compensation, health or personal records, birth date, etc.</p>
                    <p>Personal Information or Personal Data also includes ‘Sensitive personal data’.</p>
                    <p>Personal Data is each piece of information related to the individual, regardless of the form in which it is expressed and the format in which it is kept in or on the information holder (storage media, paper, tape, film, electronic media, etc).</p>
                    <p>However, any information that is freely available or accessible in public domain or is permissible to be furnished under the Right to Information Act, 2005 or any other law for the time being in force shall not be regarded as personal data.</p>
                    <p>(d) ‘Sensitive personal data or information’ – Sensitive personal data or information of a person means such personal information which consists of information relating to;</p>
                    <p>– password;</p>
                    <p>– financial information such as Bank account or credit card or debit card or other payment instrument details</p>
                    <p>– physical, physiological and mental health condition;</p>
                    <p>– sexual orientation;</p>
                    <p>– medical records and history;</p>
                    <p>– Biometric information;</p>
                    <p>– any detail relating to the above clauses as provided to body corporate for providing service; and</p>
                    <p>– any of the information received under above clauses by body corporate for processing, stored or processed under lawful contract or otherwise.</p>
                    <p>(e) “Use” means accessing the Website for any purpose including for enquiring on the Plans, log in to the Website, making any transaction on the Website etc. or entering into a transaction with Us in any other manner.</p>
                    <p>The word “Data” and “Information” are used interchangeably in this document.</p>


                </div>

                <div>
                    <h3>COLLECTION OF INFORMATION</h3>
                    <p>1. We collect contact information such as your name, address, email address and other details as given in the registration page of our App called Across Assist Secure or when you use Our Application/s, Website/s, content, product/s, and service/s (collectively the “Services” and each an “Application”) and through other interactions and communications You have with Us. This policy applies to all users of Our apps, websites, features or other services anywhere in India.</p>
                    <p>2. If you pay through the mobile app or through our website for any transaction or while utilizing the services, We will collect financial information such as debit card number, credit card number, account numbers and expiration date through the payment gateway of the credit card company or the internet banking service of your bank. We store this information for our records and to verify authenticity of the same.</p>
                    <p>3. Also, on the basis of the services utilized or as per the app settings, We may collect Your precise or approximate location information as determined through data and means such as GPS, IP address and WiFi or may also collect this information when the Across Assist App or it’s partner applications are running in the background of Your device if this collection is enabled through Your app settings or device permissions.</p>

                    <p>4. We collect information about how You interact with Our services. This includes information such as access dates and times, app features or pages viewed, app crashes and other system activity, type of browser, third-party sites or service You were using before interacting with Our services, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and clickstream data.</p>
                    <p>5. From cookies, or other technology deployed for the collection of statistical data on our website, web and mobile applications;</p>
                    <p>You understand and acknowledge that certain data/information provided by you will be shared with our Service providers for the purpose of providing the services. You also understand and acknowledge that such data/information may also be shared with our group companies for research and analysis purposes.</p>
                    <p>We continually strive to improve our website offerings based on the information and feedback we receive from you. We also monitor customer traffic patterns and site usage to help us develop the design and layout of the Bharti Secure app and / or Website.</p>
                    <p>We shall not retain any of your sensitive personal data or information unless retention of the information is required for rendering the services or is required under any Regulatory provision. Provided, however, in such case the information will be retained only for a period required for the purposes for provision of services or for a required under any applicable law as the case may be.</p>

                </div>

                <div>
                    <h3>HOW DO WE UTILIZE THE COLLECTED DATA?</h3>
                    <p>1. To create and update your account and also to verify your identity</p>
                    <p>2. To track the progress of Your requests</p>
                    <p>3. To troubleshoot software bugs and operational problems,</p>
                    <p>4. Analyzing the data either in house by the Company or through any of its group or affiliate Companies or through any duly engaged service provider;</p>
                    <p>5. Sharing the Personal Data with any Service provider duly engaged by Us, either in India or outside India for the purpose of providing any ancillary services to You, subject to We ensuring that such service provider has in place required security measures to protect Your Data both physically as well as in electronic form.</p>
                    <p>6. Disclosing any information compelled under the rules, regulations, guidelines or order of any statutory body or Court.</p>
                    <p>You hereby irrevocably and unconditionally authorise the Company to use the information provided by you to carry out your instructions and effecting transactions that you may undertake on the app / Website, in accordance with these Terms and such other terms as may be specified by the Company. The records of the Company generated by the transaction(s), including the recording of the time of the transaction(s), shall be conclusive proof of the genuineness and accuracy of such transactions.</p>
                    <p>The Personal Data and other information which We collect shall be stored either in India or abroad, subject to applicable Regulatory provisions. You hereby provide your consent to us to transfer/share your personal Data outside India for the purpose for which said information has been collected by Us. We shall always ensure that effective level of data protection to protect Your Personal Data is in place wherever Your Personal Data is stored or processed, which shall at no point be less than the regulatory compliance required in India.</p>


                </div>

                <div>
                    <h3>SHARING OF YOUR INFORMATION</h3>
                    <p>We shall not share, rent, lease, or sell your personal information (e.g. your email address, your credit card information, your name, your address, etc.) to any third party for their independent use or benefit.</p>
                    <p>We may share your information collected by us with third parties for providing you with better services and offerings as stated under the section “Usage of Information”.</p>



                </div>

                <div>
                    <h3>OPTION TO UPDATE THE PERSONAL INFORMATION</h3>
                    <p>Whenever you use our services, we aim to provide you with access to your personal information to the extent possible. If you find any of your personal information is wrong or inaccurate, we strive to give you ways to update the same. We will however verify your identity before proceeding with your request for such updation or correction.</p>
                    <p>We may reject requests that are unreasonably repetitive, risk the privacy of others or updating which would be extremely impractical.</p>

                    <p>While we endeavor to provide the updation service free of charge, we may charge a fee, if the same is permitted under laws in India.</p>



                </div>

                <div>
                    <h3>COOKIES AND LOG</h3>
                    <p>We or our Partners may store cookie when you visit our mobile app and / or Website or our Partner’s websites and access said cookies on your computer to allow you to buy and interact at the app and / or Website. The primary purpose of these cookies is to analyze how users move within our app and / or Website. Our cookies let you view customized pages while transacting with us.</p>
                    <p>Cookies are small data files that a website stores on your device to “recognize” you on subsequent requests. Our cookies do not have confidential or personally identifiable information.</p>

                    <p>We may use various tools viz., Chart tool, tracking tool etc. on our app and / or website to improve the customer experience by showing services or advertising we think you may be interested in. Also, We use analytic tools, web beacons etc. to gather anonymous, aggregated statistical information (e.g. unique hits, page views, etc.) about our app and / or Website traffic. We use Non Persistent cookies for managing the users’ logged in session and persistent cookies for managing the performance.</p>
                    <p>However, you can turn-off the Cookies in your browser.</p>



                </div>

                <div>
                    <h3>HOW DO WE PROTECT YOUR INFORMATION</h3>
                    <p>We have adequate security practices and procedures in place and a comprehensive documented information security programme and policies that contain managerial, technical, operational and physical security measures that are appropriate to protect information received by us.</p>
                    <p>Although we shall endeavor to safeguard the confidentiality of your personally identifiable information, transmissions made by means of the Internet cannot be made absolutely secure. By using this app / Website, you agree that we will have no liability for disclosure of your information due to errors in transmission or unauthorized acts of third parties.</p>



                </div>

                <div>
                    <h3>CONTACT US.</h3>
                    <p>If You have any queries relating to the processing/ usage of information provided by You or Across Assist’s Privacy Policy, You may email the Data Protection Officer (DPO) at dpo@bhartiassistance.co.in</p>



                </div>



            </div>


        </div>

        <Footer />
        </>
    )
}

export default PrivacyPolicy