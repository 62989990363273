import { redirect } from "react-router-dom";
import { Buffer } from "buffer";
function isJWTValid() {
  const token = localStorage.getItem("token");
  let tokenDetails = {
    token: "",
    role: "",
    valid: "",
    business_name:""
  };

  if (token) {
    const tokenDecodablePart = token.split(".")[1];
    const decoded = JSON.parse(
      Buffer.from(tokenDecodablePart, "base64").toString()
    );
    const currentTime = Date.now() / 1000;
    if (decoded.exp > currentTime) {
      // authContext.roleHandler(decoded.role)
      tokenDetails.role = decoded.role;
      tokenDetails.valid = true;
      tokenDetails.token = token;
      tokenDetails.business_name  = decoded.business_name

      return tokenDetails;
    }
  }
  return false;
}
export function authenticate() {
  let isTokenValid = isJWTValid();

  if (!isTokenValid) {
    return redirect("/admin");
  }
  return null;
}

export function getTokenDetails() {
  let tokenDetails = isJWTValid();
  if (!tokenDetails) {
    return null;
  }
  return tokenDetails;
}

export function authenticateAdmin() {
  let isTokenValid = isJWTValid();

  if (isTokenValid.role !== "ADMIN") {
    return redirect("/");
  }
  return null;
}

export async function createPlan({ data }) {
  console.log("====================================");
  console.log(data);
  console.log("====================================");

  let tokenDetails = getTokenDetails();

  try {
    const response = await fetch(
      "https://spa-insurance.forklyft.in/v1/dealer/create-plan",
      {
        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: JSON.stringify(data),

        // Adding headers to the request
        headers: {
          Authorization: "Bearer " + tokenDetails.token,
          "Content-Type": "application/json",
        },
      }
    );
    const resp = await response.json();
    if (resp.status === 201) {
      return resp;
    } else {
      alert(resp.message);
    }
    console.log(resp);
  } catch (error) {
    console.log(error);
  }
}

export async function getPlans() {
  let tokenDetails = getTokenDetails();

  try {
    const response = await fetch(
      "https://spa-insurance.forklyft.in/v1/dealer/get-vehicle-plans",
      {
        // Adding method type
        method: "GET",

        // Adding headers to the request
        headers: {
          Authorization: "Bearer " + tokenDetails.token,
          "Content-type": "application/json",
        },
      }
    );
    const resp = await response.json();
    if ((resp.status === 200)) {
      return resp.message;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function togglePlan({ id }) {
  let tokenDetails = getTokenDetails();

  try {
    const response = await fetch(
      "https://spa-insurance.forklyft.in/v1/dealer/toggle-plan",
      {
        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: JSON.stringify({ planId: id }),

        // Adding headers to the request
        headers: {
          Authorization: "Bearer " + tokenDetails.token,
          "Content-Type": "application/json",
        },
      }
    );
    const resp = await response.json();
    if (resp.status === 201) {
      alert(resp.message);
    } else {
      alert(resp.message);
    }
    console.log(resp);
  } catch (error) {
    console.log(error);
  }
}

export async function getDealers() {
  let tokenDetails = getTokenDetails();

  try {
    const response = await fetch(
      "https://spa-insurance.forklyft.in/v1/dealer/get-dealer-pagination?page=1",
      {
        // Adding method type
        method: "GET",

        // Adding headers to the request
        headers: {
          Authorization: "Bearer " + tokenDetails.token,
          "Content-type": "application/json",
        },
      }
    );
    const resp = await response.json();
    return resp.message;
  } catch (error) {
    console.log(error);
  }
}

export async function editDealerOverview({ id, disableLimit, limit }) {
  try {
    let { token } = getTokenDetails();

    const response = await fetch(
      "https://spa-insurance.forklyft.in/v1/dealer/edit-dealer-overview",
      {
        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: JSON.stringify({
          disable_credit: disableLimit,
          total_credit_limit: limit,
          dealer_id: id,
        }),

        // Adding headers to the request
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    const resp = await response.json();
    if (resp.status === 200) {
      alert(resp.message);
    } else {
      alert(resp.message);
    }
    console.log(resp);
  } catch (error) {
    console.log(error);
  }
}

export async function  toggleDealerStatus  ({ id, active })  {
  console.log('====================================');
  console.log(id,active,"OP");
  console.log('====================================');
    

    try {
      let { token } = getTokenDetails();

      const response = await fetch(
        "https://spa-insurance.forklyft.in/v1/dealer/toggle-dealer-active",
        {
          // Adding method type
          method: "POST",

          // Adding body or contents to send
          body: JSON.stringify({
            dealer_id: id,
            active: String(!active), //"true"/"false"
          }),

          // Adding headers to the request
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      );
      const resp = await response.json();
      if (resp.status === 200) {
        alert(resp.message);
      } else {
        alert(resp.message);
      }
      console.log(resp);
    } catch (error) {
      console.log(error);
    }

    
  };