import { getTokenDetails } from '../utils/auth';
import AuthContext from '../store/auth-context';
import React,{useEffect , useState , useContext} from 'react'

const Profile = () => {
    const context = useContext(AuthContext);
    const [dealerData, setDealerData] = useState(false);


    useEffect(() => {
       getDealer()
    }, []);


    const getDealer = async () => {
        let tokenDetails = getTokenDetails()


        const response = await fetch("https://spa-insurance.forklyft.in/v1/dealer/get-dealer", {

            // Adding method type
            method: "GET",



            // Adding headers to the request
            headers: {
                'Authorization': 'Bearer ' + tokenDetails.token,
                "Content-type": "application/json"
            }
        }
        )
        const resp = await response.json();
        setDealerData(resp.message)
        // console.log(resp)


    }

    if(!dealerData){
        return(
            <p>Loading Data...</p>
        )

    }





    return (
        <div className=' w-[80%]'>
            <h1 className='text-left text-2xl font-semibold '>Dealer Details</h1>

            <div className='bg-[#fff] flex-wrap gap-6 flex mt-[3%] pt-[5%] px-8'>
                <div className='flex flex-col justify-start items-start border-b-2 pb-6 w-full'>
                    <h2 className='text-xl'>SPA 1</h2>
                    <p className='text-[#a1a8c3] text-left text-xl w-full'>-{dealerData.address_line_1} <br/> -{dealerData.address_line_2}
                    </p>
                </div>

                <div className='flex justify-start w-full border-b-2 pb-6'>
                    <div className='text-left w-[33%]'>
                        <h3>Parent Dealer</h3>
                        <p>-</p>
                    </div>

                    <div className='text-left w-[33%]'>
                        <h3>Partner/Brand</h3>
                        <p className='text-[#a1a8c3] font-semibold w-[50%]'>{dealerData.business_name}</p>
                    </div>

                    <div className='text-left w-[33%]'>
                        <h3> Dealer Code</h3>
                        <p>na</p>
                    </div>

                </div>


                <div className='flex justify-start w-full border-b-2 py-6'>
                    <div className='text-left w-[33%]'>
                        <h3 className='text-xl font-[400] mb-2'>GST Number</h3>
                        <p className='text-[#646c9a] font-semibold text-xl'>{dealerData.gst_number} </p>
                    </div>

                    <div className='text-left w-[33%]'>
                        <h3 className='text-xl font-[400] mb-2'>PAN</h3>
                        <p className='text-[#646c9a] font-semibold text-xl'>{dealerData.pan}</p>
                    </div>

                    

                </div>

                <div className='flex justify-start w-full py-6'>
                    <div className='text-left w-[33%]'>
                        <h3 className='text-xl font-[400] mb-2'>Banking Name</h3>
                        <p className='text-[#646c9a] font-semibold text-xl'>{dealerData.bank_name}</p>
                    </div>

                    <div className='text-left w-[33%]'>
                        <h3 className='text-xl font-[400] mb-2'>IFSC</h3>
                        <p className='text-[#646c9a] font-semibold text-xl'>{dealerData.bank_ifsc}</p>
                    </div>

                    <div className='text-left w-[33%]'>
                        <h3 className='text-xl font-[400] mb-2'>Bank Account Number</h3>
                        <p className='text-[#646c9a] font-semibold text-xl'>{dealerData.bank_account_number}</p>
                    </div>



                </div>






            </div>

            <div className='text-[#646c9a] py-8 text-left'>
                2024 ©
                Powered by Across Assist
            </div>





        </div>
    )
}

export default Profile