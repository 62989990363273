import React from 'react'

const Step3 = ({data,setData}) => {


    const handleChange = (e) => {
        let value = e.target.value
        let ele = document.getElementsByName(e.target.name)[0].parentNode;
        let errorText = ele.querySelector('.error-text')
        if (value !== '' && errorText) {
            errorText && ele.removeChild(errorText)
        }
        setData({
            ...data,
            [e.target.name]: value
        });

        // if (e.target.name === 'vehicle_type') {
        //     getVehicleBrands(value);

        // }
        // if (e.target.name === 'make') {
        //     getVehicleModels(value);

        // }
    }




  return (
      <div className='w-[60%] '>
          <h2 className='text-[#000] font-semibold text-2xl text-left'>Customer Details</h2>
          <div className=' w-full flex mt-[5%] justify-between flex-wrap gap-y-[1.5rem]'>
              <div className="form-group  text-left">
                  <label>First Name</label>
                  <div className="">
                      <input type="text" name='first_name' onChange={handleChange} value={data.first_name}/>

                  </div>
              </div>

              <div className="form-group  text-left">
                  <label>Last Name</label>
                  <div className="">
                      <input type="text" name='last_name' onChange={handleChange} value={data.last_name}/>

                  </div>
              </div>

              <div className="form-group  text-left">
                  <label>Email</label>
                  <div className="">
                      <input type="email" name='email' onChange={handleChange} value={data.email}/>

                  </div>
              </div>

              <div className="form-group w-[45%] text-left">
                  <label>Mobile Number</label>
                  <div className="custom-input">
                      <span>+91</span>
                     <input type="text" name='mobile_number' onChange={handleChange} value={data.mobile_number}/>
                  </div>
              </div>

             

          </div>

          <h2 className='text-[#000] font-semibold text-2xl text-left sm:mt-14'>Address</h2>
          <div className=' w-full flex mt-[5%] justify-between flex-wrap gap-y-[1.5rem]'>
              <div className="form-group  text-left">
                  <label>Address Line 1</label>
                  <div className="">
                      <input type="text" name='address_line_1' onChange={handleChange} value={data.address_line_1}/>

                  </div>
              </div>

              <div className="form-group  text-left">
                  <label>Address Line 2</label>
                  <div className="">
                      <input type="text" name='address_line_2' onChange={handleChange} value={data.address_line_2}/>

                  </div>
              </div>

              <div className="form-group  text-left">
                  <label>City</label>
                  <div className="">
                      <input type="text" placeholder='City' name='city' onChange={handleChange} value={data.city}/>

                  </div>
              </div>

              <div className="form-group  text-left">
                  <label>Pincode</label>
                  <div className="">
                      <input type="text" placeholder='Pincode' name='pincode' onChange={handleChange} value={data.pincode}/>

                  </div>
              </div>

              <div className="form-group  text-left">
                  <label>State</label>
                  <div className="">
                      <input type="text" placeholder='State' name='state' onChange={handleChange} value={data.state}/>

                  </div>
              </div>

              <div className="form-group  text-left">
                  <label>Country</label>
                  <div className="">
                      <input type="text" placeholder='Country' name='country' onChange={handleChange} value={data.country}/>


                  </div>
              </div>



          </div>


          <h2 className='text-[#000] font-semibold text-2xl text-left sm:mt-14'>Nominee</h2>
          <div className=' w-full flex mt-[5%] justify-between flex-wrap gap-y-[1.5rem]'>
              <div className="form-group  text-left">
                  <label>Nominee Name</label>
                  <div className="">
                      <input type="text" placeholder='Name' name='nominee_name' onChange={handleChange} value={data.nominee_name}/>

                  </div>
              </div>

              <div className="form-group  text-left">
                  <label>Relation</label>
                  <div className="">
                    

                      <select className="" name="nominee_relation" id="input--vehicle-type"  onChange={handleChange} value={data.nominee_relation}>
                          <option value="" hidden>Select Relation</option>
                          <option value="daughter">Daughter</option>
                          <option value="wife">Wife</option>
                          <option value="son">Son</option>
                          <option value="husband">Husband</option>
                          <option value="father">Father</option>
                          <option value="mother">Mother</option>
                          <option value="others">Others</option>

                      </select>

                  </div>
              </div>

              <div className="form-group  text-left">
                  <label>Date of Birth</label>
                  <div className="">
                      <input type="date" placeholder='Date of Birth' name='nominee_dob' onChange={handleChange} value={data.nominee_dob}/>

                  </div>
              </div>

              <div className="form-group  text-left">
                  <label>Gender</label>
                  <div>

                  <select className="" name="nominee_gender" id="input--vehicle-type" title="Select vehicle type"  onChange={handleChange} value={data.nominee_gender}>
                          <option value="" hidden>Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">female</option>
                      
                  </select>
                  </div>
              </div>

           



          </div>



          <div>

          </div>
      </div>
  )
}

export default Step3